<template>
    <div class="relative">
        <img :src="image" class="absolute" v-bind:style="{ marginTop: '18px', marginLeft: '10px' }" alt="">
        <input :type="type" :name="name" class="input-text w-full" :placeholder="placeholder" :style="{ paddingLeft: '40px' }" v-model="inputVal" :required="required">
    </div>
</template>

<script>
export default {
    name: 'InputField',
    props: [ 'type', 'name', 'image', 'placeholder', 'modelValue', 'required'],
    computed: {
        inputVal: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit('update:modelValue', val);
            }
        }
    }
}
</script>