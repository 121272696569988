<template>
    <div>
        <div class="flex sm:items-center flex-wrap sm:flex-row flex-col gap-x-4">
            <h1 v-if="english">API documentation</h1>
            <h1 v-else>API dokumentasjon</h1>
            <div class="hover:underline cursor-pointer" @click="toggleLanguage()">
                <span v-if="english">[ <img src="/img/icon/flag_no.png" class="inline"/> Norwegian version ]</span>
                <span v-else>[ <img src="/img/icon/flag_uk.png" class="inline"/> English version of API documentation ]</span>
            </div>
        </div>
        <div class="py-4">         
            <div v-if="english">
                To use the API your account has to be authorized for this. On Produkter-page you can check if API is activated on your account.
                Order access directly or <router-link to="/kontakt">contact us</router-link> if you need to activate API.            
            </div>   
            <div v-else>
                For å benytte vårt API må din konto være aktivert for dette. På Produkter-siden kan man se om kontoen har API aktivert. 
                Bestill tilgang til API direkte eller <router-link to="/kontakt">kontakt oss</router-link> dersom du ønsker å aktivere API.
            </div>
        </div>

        <div class="pt-6 pb-4 relative">
            <div class="absolute w-full -mt-16">
                <img style="float:right" src="/img/plane-green-left.svg" alt="">
            </div>

            <div class="flex flex-wrap justify-start">                
                <div v-for="(page,i) in pages" :key="i" class="relative px-2 pt-2 sm:px-6 text-center" @mouseover="hoverPage=page.path" @mouseleave="hoverPage=''">
                    <router-link :to="{name: page.name, params: {english: english}}" :class="{'font-bold' : activePage === page.path}" @click.passive="activePage = page.path" class="font-semibold text-gray-900 no-underline hover:no-underline">
                        {{ english ? page.alias_eng : page.alias }}
                    </router-link>
                    <div v-if="activePage === page.path || hoverPage === page.path" class="w-full absolute -ml-2 sm:-ml-6 mb-4 border-b-4 border-blue-600"></div>
                </div>
            </div>
            <div class="border-t-2 border-dashed"> </div>
        </div>

        <router-view :key="$route.fullPath" :english="english"></router-view>
    </div>
    
</template>

<script>

export default {
    data() {
        return {
            activePage: '',
            hoverPage: '',
            pages: [
                { path: 'send', name: 'ApiSend', alias: 'Send SMS', alias_eng: 'Send SMS'},
                { path: 'motta', name: 'ApiMO', alias: 'Motta SMS', alias_eng: 'Receive SMS'},
                { path: 'lev', name: 'ApiDLR', alias: 'Leveringsrapport', alias_eng: 'Delivery report'},                
                { path: 'grupper', name: 'ApiGroups', alias: 'Grupper', alias_eng: 'Groups'},
                { path: 'div', name: 'ApiMisc', alias: 'Diverse', alias_eng: 'Misc'},
            ],
            english: false,
        }
    },
    mounted: function() {
        this.english = this.$route.params.english === 'true';
        let p = this.$route.path.split('/');
        this.activePage = p[p.length-1];        
    },
    methods: {
        toggleLanguage: function() {
            this.english = !this.english;
        }
    }
}
</script>