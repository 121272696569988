
<template>

  <header class="bg-white fixed w-full sm:flex justify-between sm:px-4 sm:py-6 z-10" :class="atTopOfPage && !isOpen ? '' : 'shadow-2xl'">
    <div class="flex items-center justify-between px-4 py-3 sm:p-0">
      <div>
        <router-link to="/" @click.passive="closeMenu" class="focus:shadow-none"><img class="h-8 sm:h-10 sm:ml-4" src="/img/logo-sveve.svg" alt="Sveve logo"></router-link>
      </div>
      <div class="sm:hidden">
        <button @click="toggleMenu()" type="button" class="block text-gray-500 hover:text-gray-700 focus:shadow-none">
          <svg class="h-8 w-8 fill-current" viewBox="0 0 24 24">
            <path v-if="isOpen" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            <path v-if="!isOpen" fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          </svg>
        </button>
      </div>
    </div>

    <nav id="menu" :class="isOpen ? 'block' : 'hidden'" class="px-2 pt-2 pb-4 sm:flex sm:p-0 lg:text-lg">        
        <router-link to="/tjenester" @click.passive="closeMenu" class="menuItem sm:ml-16">Tjenester og priser</router-link>
        <router-link to="/kontakt" @click.passive="closeMenu" class="menuItem mt-1 sm:mt-0 sm:ml-2">Kontakt oss</router-link>
        <router-link to="/hjelp" @click.passive="closeMenu" class="menuItem mt-1 sm:mt-0 sm:ml-2">Hjelp</router-link>
        <router-link to="/artikler" @click.passive="closeMenu" class="menuItem mt-1 sm:mt-0 sm:ml-2">Nyheter</router-link>
        <div class="sm:hidden pt-4 mb-4 border-b border-gray-300"></div>
        <span class="sm:ml-auto sm:pl-10 flex justify-end">
            <router-link to="/bestill" @click.passive="closeMenu" class="btn btn-blue">Prøv gratis</router-link>
            <router-link to="/login" @click.passive="closeMenu" class="menuItem ml-2 highlight">Logg inn</router-link>
        </span>
    </nav>
    
  </header>
  <div class="mt-16 sm:mt-32"></div> <!-- dummy for top margin -->

</template>

<script>
    export default {
        data() {
            return {
                isOpen: false,
                atTopOfPage: true
            }
        },
        beforeMount () {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
              this.atTopOfPage = window.scrollY === 0;
            },
            closeMenu() {
              this.isOpen = false;
            },
            toggleMenu() {
              this.isOpen = !this.isOpen;   
            }
        }
    }
 
</script>
