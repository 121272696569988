<template>
    
    <div v-html="isAutoApproved ? autoApprovedTxt : manualApproveTxt"></div>

    <div class="py-8 flex justify-between">
        <router-link to="/" class="btn btn-gray">&#8592; Til forsiden</router-link>
        <router-link v-if="isAutoApproved" to="/login" class="btn btn-blue">Logg inn</router-link>
    </div>    
    
</template>

<script>
export default {
    computed: {
        isAutoApproved: function() {
            return this.$store.getters.trialOrder.autoApproved;
        }
    },
    mounted() {
        const trialOrder = this.$store.getters.trialOrder;
        if (trialOrder.transactionObject) {

            //window.dataLayer.push(trialOrder.transactionObject);  // Google Analytics (UA)

            // Google Analytics (GA4)
            //console.log(trialOrder.transactionObject.ga4);
            this.$gtag.event('purchase', trialOrder.transactionObject.ga4);

            this.$store.commit('clearTrialOrder');
        }
    },
    data() {
        return {
            manualApproveTxt: '<p>En kundebehandler vil behandle din bestilling i løpet av kort tid. Når prøvekontoen er klar til bruk vil du motta en e-post med ditt brukernavn og passord.</p><p>NB! Dersom du liker tjenesten kan du enkelt oppgradere til en ordinær konto inne fra din prøvekonto.</p><p>Dette er en tjeneste for bedrifter og organisasjoner.</p>',
            autoApprovedTxt: '<p>Din prøvekonto er nå opprettet og klar til bruk. Brukernavn og passord er sendt til din innboks.</p><p>NB! Dersom du liker tjenesten kan du enkelt oppgradere til en ordinær konto inne fra din prøvekonto.</p>',        
        }
    }
}
</script>