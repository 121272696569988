<template>

    <OptionCard :name="productHeading" :sub="productSubHeading" :desc="productDesc" :price="productPrice" :alwaysOn="true" id="BAS" />

    <div class="pb-2 font-semibold text-sm" v-html="optionsHeading"></div>
    <div v-for="product in products" :key="product.id">        
        <OptionCard :name="product.name" :sub="product.shortDesc" :desc="product.longDesc" :id="product.shortName" :price="product.price" :startup="product.startupPrice" />
    </div>

    <div class="text-red-600">{{ connError }}</div>
   
</template>

<script>
import OptionCard from '@/components/OptionCard.vue';
import { Settings } from '@/main.js';
import axios from 'axios';


export default {
    name: 'OrderProduct',
    components: {
        OptionCard
    },
    data() {
        return {
            products: [],
            connError: '',    
            productDesc: '',

            // ----------- Texts --------------------
            productHeading: 'SMS-tjeneste - grunnpakke',
            productSubHeading: 'Grunnpakken er en komplett SMS-tjeneste og inneholder vår mest populære funksjonalitet. Grunnpakken kan utvides med flere funksjoner etter behov.',        
            productPrice: 129,
            productBullets: [
                'Brukervennlig SMS-tjeneste',                
                'Valgfri avsender, f.eks. firma- eller produktnavn',
                'Toveis-kommunikasjon hvor meldinger kan besvares',
                'Ubegrenset antall kontakter og grupper',
                'Support og hjelp inkludert',
            ],
            optionsHeading: 'Valgfrie utvidelser til grunnpakken'
        }
    },
    created: function() {
        const options = {
            url: Settings.server.api + '/products',
            method: 'GET',
            timeout: 3000          
        };
        axios(options).then(response => {  
            this.products = response.data;
        }).catch(error => {
            console.log(error);
            this.connError = 'Feil: ' + error.message;
        });

        this.productBullets.forEach((text) => {
            this.productDesc += '<div class="font-semibold flex ml-2 sm:ml-4 mb-1"><img class="mr-3" src="/img/icon/tick.svg" alt=""> ' + text + '</div>';
        });
    }    
}
</script>