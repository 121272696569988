<template>

    <div class="font-semibold pt-4">
        <span v-if="english">Number of SMS remaining</span>
        <span v-else>Antall gjenstående SMS</span>
    </div>
    <div class="api-example"> 
        https://sveve.no/SMS/AccountAdm?cmd=sms_count&user=BRUKERNAVN&passwd=API-PASSORD
    </div>

    <div class="font-semibold pt-6">
        <span v-if="english">Order SMS</span>
        <span v-else>Bestille SMS</span>
    </div>
    <div class="api-example"> 
        https://sveve.no/SMS/AccountAdm?cmd=order_sms&count=5000&user=BRUKERNAVN&passwd=API-PASSORD
    </div>
    <div class="text-sm">        
        <strong>count</strong> 
        <span v-if="english"> is an integer between 500 and 100000</span>
        <span v-else> er et heltall mellom 500 og 100000</span>
    </div>

</template>

<script>
export default {
    props: {
        english: Boolean,
    },    
}
</script>