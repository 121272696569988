<template>
    <div v-if="group">
        <h2>{{ group.name }}</h2>
        <div>
            <div v-for="(member, m) in group.members" :key="m">
                {{ getMember(member) }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Settings } from '@/main.js';

export default {

    data() {
        return {
            group: null,
        }
    },
    created() {
        const path = window.location.pathname.split('/');        
        const groupId = path[path.length-1];
        this.getGroup(groupId);
    },
    methods: {
        getGroup: function(groupId) {
            axios.get(Settings.server.api + '/group?id=' + groupId).then(res => {
                this.group = res.data;
                console.log(this.group);
            });
        },
        getMember: function(member) {
            return member.name + ' ' + member.number;
        },
    }
}

</script>