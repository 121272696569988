<template>

    <h2 v-if="english">Incoming messages to prefix or dedicated numbers</h2>
    <h2 v-else>Innkommende meldinger til kodeord og dedikert nummer</h2>
    <div class="pb-4"> 
        <div v-if="english">
            Configure forwarding of incoming messages to prefix or dedicated number assigned to your account (choose GET or POST). 
            Replace the example address shown in the red box below with an address on your server.
            You find this setting on the <strong>Kodeord</strong> or <strong>Dedikert nummer</strong>-pages:<br>
        </div>
        <div v-else>
            Dersom kodeord eller dedikert 8-sifret nummer er tilknyttet kontoen kan man konfigurere videresending av innkommende meldinger til egen server (velg mellom GET og POST).
            Dette gjøres på <strong>Kodeord</strong> eller <strong>Dedikert nummer</strong>-sidene:<br>
        </div>
        <div class="my-6 border border-gray-300 shadow-lg inline-block">
            <img src="/img/api-help-mo.png" alt="">
        </div>
    </div>
    <div class="py-2 font-semibold">Parametere</div>
    <table class="api-table">
        <thead>
            <tr><th>Parameter</th><th>Type</th><th>{{ english ? 'Description' : 'Beskrivelse' }}</th></tr>
        </thead>
        <tbody>
            <tr v-for="(p,i) in english ? moParams_eng : moParams" :key="i">
                <td v-html="p.param"></td>
                <td v-html="p.type"></td>
                <td v-html="p.desc"></td>
            </tr>
        </tbody>
    </table>    
    <div class="text-sm">
        <span v-if="english">* = Requires that number lookup is activated</span>
        <span v-else>* = Forutsetter at nummeroppslag er aktivert for kodeordet</span>
    </div>

    <div class="pt-6 pb-2">
        <span v-if="english">Forward request example (get):</span>
        <span v-else>Eksempel på et kall (get) scriptet må kunne motta:</span>
    </div>
    <div class="api-example">
        https://mittdomene.no/incoming_sms.php?number=91654321&msg=Meldingsteksten&prefix=BALL&shortnumber=27333&name=&address=
    </div>

    <div class="border-t-2 border-dashed mt-8"></div>
    
    <h2 v-if="english">Message replies</h2>
    <h2 v-else>Svar på sendte meldinger</h2>
    <div>
        <div v-if="english">
            On the page <strong>API -> Innstillinger</strong> you can configure an URL to be called for each reply received from messages sent with reply=true.          
            Replace the example address shown in the red box below with an address on your server. Choose GET or POST as method.
        </div>
        <div v-else>
            På siden <strong>API -> Innstillinger</strong> kan man definere en URL som skal motta svar på meldinger som sendes med svarmulighet aktivert (reply=true). 
            Det gjøres da et kall til angitt URL for hvert svar som mottas. Velg GET eller POST.
        </div>
        <div class="my-6 border border-gray-300 shadow-lg inline-block">
            <img src="/img/api-help-reply.png" alt="">
        </div>    
    </div>
    <div class="pt-4 pb-2 font-semibold">Parametere</div>
    <table class="api-table">
        <thead>
            <tr><th>Parameter</th><th>Type</th><th>{{ english ? 'Description' : 'Beskrivelse' }}</th></tr>
        </thead>
        <tbody>
            <tr v-for="(p,i) in english ? replyParams_eng : replyParams" :key="i">
                <td v-html="p.param"></td>
                <td v-html="p.type"></td>
                <td v-html="p.desc"></td> 
            </tr>            
        </tbody>
    </table>
    <div class="pt-6 pb-2">
        <span v-if="english">Forward request example (get):</span>
        <span v-else>Eksempel på et kall (get) scriptet må kunne motta:</span>
    </div>
    <div class="api-example">
        https://mittdomene.no/sms/reply.php?number=91654321&msg=Svarmeldingen&id=42824122
    </div>    

    <div class="border-t-2 border-dashed mt-8"></div>

    <ApiDocPushInterval :english="english" />
    
    <div v-if="english">
        <h2>Charset/encoding</h2>
        All requests uses UTF-8 encoding. The receiver must take this into account for correct character representation.
    </div>
    <div v-else>
        <h2>Tegnsett/encoding</h2>
        Alle kallene benytter UTF-8 encoding og mottaket må derfor ta høyde for dette for at tegnsett skal bli korrekt. 
    </div>

</template>

<script>
import ApiDocPushInterval from '@/components/APIdocPushInterval.vue';

export default {
    components: {
        ApiDocPushInterval,
    },    
    props: {
        english: Boolean,
    },
    data() {
        return {
            moParams: [
                {
                    param: 'number',
                    type: 'Long',
                    desc: 'Avsenders mobilnummer'
                },
                {
                    param: 'msg',
                    type: 'String',
                    desc: 'Meldingsteksten'
                },
                {
                    param: 'prefix',
                    type: 'String',
                    desc: 'Kodeord (gjelder kun 4- og 5-sifrede nummer)'
                },
                {
                    param: 'shortnumber',
                    type: 'Integer',
                    desc: 'Kortnummer (evt. 8-sifret nummer)'
                },
                {
                    param: 'name*',
                    type: 'String',
                    desc: 'Avsenders navn'
                },
                {
                    param: 'address*',
                    type: 'String',
                    desc: 'Avsenders adresse'
                },                                                                                
            ],
            moParams_eng: [
                {
                    param: 'number',
                    type: 'Long',
                    desc: 'Senders mobile number'
                },
                {
                    param: 'msg',
                    type: 'String',
                    desc: 'Message text'
                },
                {
                    param: 'prefix',
                    type: 'String',
                    desc: 'Prefix (applies only to 4- and 5-digit numbers)'
                },
                {
                    param: 'shortnumber',
                    type: 'Integer',
                    desc: 'Number (4, 5 or 8 digits)'
                },
                {
                    param: 'name*',
                    type: 'String',
                    desc: 'Senders name'
                },
                {
                    param: 'address*',
                    type: 'String',
                    desc: 'Senders address'
                },                                                                                
            ],
            replyParams: [
                {
                    param: 'msg',
                    type: 'String',
                    desc: 'Svarmelding'
                },
                {
                    param: 'number',
                    type: 'Long',
                    desc: 'Mobilnummeret svaret kommer fra'
                },
                {
                    param: 'id',
                    type: 'Integer',
                    desc: 'Meldings-ID'
                },                                
            ],
            replyParams_eng: [
                {
                    param: 'msg',
                    type: 'String',
                    desc: 'Reply message'
                },
                {
                    param: 'number',
                    type: 'Long',
                    desc: 'Senders mobile number'
                },
                {
                    param: 'id',
                    type: 'Integer',
                    desc: 'Message ID'
                },                                
            ]               
        }
    }
}
</script>