<template>
    <div :class="{'h-screen': !pageRenderFinish}">
        <div class="sm:mx-10 lg:mx-40" v-if="pageRenderFinish">

            <div class="flex flex-wrap justify-between items-center">
                <div>
                    <h1 class="text-3xl">{{ heading }}</h1>
                    <div class="pt-2 pb-2" v-html="subHeading"></div>
                </div>
                <div class="rounded-full border border-gray-400 p-1 flex">
                    <div class="plan" :class="{'selected': plan === 'year'}" @click="selectPlan('year')">Årlig</div>
                    <div class="plan" :class="{'selected': plan === 'month'}" @click="selectPlan('month')">Månedlig</div>
                </div>
            </div>

            <div class="line-hor" />

            <div class="pt-2 pb-6 flex justify-between">
                <div>
                    <div class="text-2xl pb-2">{{ heading2 }}</div>
                    <div v-html="subHeading2"></div>
                </div>       
                <img class="hidden sm:block -mt-10" src="/img/plane-purple-left.svg" alt="">
            </div>

            <div class="pb-4">            
                <div class="pb-2">{{ bulletsHeading }}</div>
                <div class="flex flex-wrap justify-between pb-4">
                    <div class="pb-4 sm:w-2/3">
                        <div v-for="(bullet, i) in bullets" :key="i" class="flex pb-1">                
                            <img class="pr-2" src="/img/icon/tick.svg" alt=""><span v-html="bullet"></span>
                        </div>
                    </div>
                    <ServicePrice :serviceInfo="getProductInfo('BAS')" :smsPrices="smsPrices" :plan="plan" />  
                </div>

                <router-link to="/bestill" class="btn btn-blue">{{ btnOrder }}</router-link>
                <router-link to="/sms-tjeneste" class="btn btn-gray hover-arrow">{{ btnReadMore }}</router-link>
            </div>

            <hr class="line-hor"/>

            <div class="relatve pt-2 sm:pt-4 pb-4 sm:pb-8">
                <img class="absolute hidden lg:block -ml-40" src="/img/plane-blue-right.svg" alt="">
                <div class="font-bold text-2xl" id="optionsHeading">{{ optionsHeading }}</div>
                <div class="pt-1">{{ optionsText }}</div>
            </div>

            <ServiceCard :serviceInfo="getProductInfo('API')" :smsPrices="smsPrices" :plan="plan" :isTrial="true" :heading="apiHeading" :desc="apiDesc" :moreInfoLink="'/sms-api'" />
            <ServiceCard :serviceInfo="getProductInfo('KOD')" :smsPrices="smsPrices" :plan="plan" :isTrial="true" :heading="kodHeading" :desc="kodDesc" :moreInfoLink="'/kodeord'" />                         
            <ServiceCard :serviceInfo="getProductInfo('EPS')" :smsPrices="smsPrices" :plan="plan" :isTrial="true" :heading="epsHeading" :desc="epsDesc" :moreInfoLink="'/epost-til-sms'" />  
            <ServiceCard :serviceInfo="getProductInfo('DED')" :smsPrices="smsPrices" :plan="plan" :isTrial="false" :heading="dedHeading" :desc="dedDesc" :moreInfoLink="'/dedikert-nummer'" /> 


            <div class="font-semibold text-2xl pt-4 pb-2">{{ smsHeading }}</div>
            <div class="pb-4" v-html="smsDesc"></div>
            <div class="flex">
                <div class="pr-10"></div>
                <div class="text-right">
                    <div v-for="(p,i) in smsPrices" :key="i">
                        <div>{{ p.count }} stk - {{p.price}} øre/stk</div>
                    </div>                
                </div>                    
            </div>
            <div class="pt-2" v-html="smsInfo"></div>

            <div class="flex justify-end">
                <img src="/img/plane-blue-left.svg" alt="">
            </div>

        </div>    
    </div>
    <end-page v-if="pageRenderFinish" />
</template>

<script>
import { mapActions } from 'vuex';
import { Settings } from '@/main.js';
import axios from 'axios';
import ServicePrice from '@/components/ServicePrice.vue';
import ServiceCard from '@/components/ServiceCard.vue';

export default {
    name: 'Products',
    components: {
        ServicePrice,
        ServiceCard
    },
    data() {
        return {
            products: [],  // from api
            smsPrices: [],
            pageRenderFinish: false,

            heading: 'Tjenester og priser',
            subHeading: 'Oversikt over tjenester og priser',
            heading2: 'SMS-tjeneste - grunnpakke',
            subHeading2: 'Grunnpakken er en komplett SMS-tjeneste og inneholder vår mest populære funksjonalitet. Grunnpakken kan utvides med flere funksjoner etter behov (se <strong>Utvidelser</strong>).',
            bulletsHeading: 'Noen av funksjonene som er inkludert i grunnpakken:',
            bullets: [
                'Brukervennlig SMS-tjeneste',
                'Valgfri avsender, f.eks. firma- eller produktnavn',
                'Toveis kommunikasjon hvor meldinger kan besvares',
                'Ubegrenset antall kontakter og grupper',
                'Support og hjelp inkludert',
            ],            
            plan: 'year',
            btnReadMore: 'Les mer',
            btnOrder: 'Bestill gratis prøvekonto',
            optionsHeading: 'Utvidelser',
            optionsText: 'Grunnpakken er en komplett SMS-tjeneste som dekker mange behov for mobil kommunikasjon, og kan utvides med følgende funksjonalitet:',
            apiHeading: 'API',
            apiDesc: 'API (application programming interface) er en måte å lage integrasjoner mellom dataprogrammer på. En integrasjon settes enkelt opp av din IT-utvikler. SMS-API kan brukes for å bygge inn skreddersydd SMS-utsendelse i dine IT-systemer eller i din nettside.',
            kodHeading: 'Kodeord',
            kodDesc: 'Kodeord et ord som du disponerer for å motta innkommende SMS på et kortnummer. Kodeordet velger du selv, gjerne ditt firma- eller produktnavn. Kodeordet har mange bruksområder, feks automatiske inn- og utmeldinger eller mottak av kundehenvendelser.',
            dedHeading: 'Dedikert nummer',
            dedDesc: 'Dedikert nummer er et 8-sifret telefonnummer du disponerer til å motta innkommende SMS.<br><a href="/kontakt">Kontakt oss</a> for å prøve dedikert nummer.',
            epsHeading: 'E-post til SMS',
            epsDesc: 'Tjenesten gjør om innkommende e-post til SMS. Et vanlig bruksområde er teknisk utstyr som kan sende alarmer per e-post, men hvor man ønsker raskere varsling via SMS. E-posten videresendes som SMS til dynamiske mobilnumre eller forhåndsdefinerte mottakergrupper.',
            smsHeading: 'SMS-priser',      
            smsDesc: 'SMS kan kjøpes i ulike pakker. Pakkene går ikke ut på dato og kjøpte SMS brukes så lenge du er kunde hos Sveve. Du bestiller selv nye SMS-pakker ved behov. For kunder med fast, høyt volum tilbyr vi også månedlig etterfakturering.',
            smsInfo: 'Etterfakturering etter avtale (over 5000 SMS/mnd)',
        }
    },
    created: function() {

        let options = {
            url: Settings.server.api + '/smsprice', 
            method: 'GET',
            timeout: 3000          
        };
        axios(options).then(response => {  
            this.smsPrices = response.data;
            options.url = Settings.server.api + '/products/all';
            axios(options).then(response => {  
                this.products = response.data;               
                this.pageRenderFinish = true;
            }).catch(error => {
                console.log(error);
            });             

        }).catch(error => {
            console.log(error);
        });

    },
    methods: {
        ...mapActions([
            'orderProduct'
        ]),
        getProductInfo: function(product) {
            let ret = {};
            this.products.forEach((p) => {
                if (p.shortName === product) {
                    ret = p;
                    return;
                }
            });
            return ret;
        },
        selectPlan: function(plan) {
            this.plan = plan;
        }
    }, 
    
}
</script>

<style scoped>
.plan {
    @apply rounded-full font-semibold w-24 py-1 cursor-pointer text-center;
}
.plan.selected {
    @apply bg-gray-500 text-white;
}
</style>