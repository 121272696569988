  
<template>
  <div class="flex antialiased">
    <div class="min-h-screen w-full">
      <Navbar v-if="showNavbar" />
      <main class="px-4 sm:px-6 lg:px-12 xl:px-24">
        <router-view :key="$route.fullPath" />
      </main>
      <div v-if="showFooter">
        <Footer style="background-image: url('/img/background-blue.svg')" />
        <div class="text-center py-4 text-sm">
          2006 - {{ new Date().getFullYear() }} &copy; Sveve AS | <router-link to="/cookies">Cookies</router-link> | <a href="/personvernerklaering.pdf">Personvernerklæring</a>      
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  watch: {
    $route(to) {
      this.$store.state.showNavbar = to.meta.hideNavbar !== true;      
      this.$store.state.showFooter = to.meta.hideFooter !== true;  
      document.title = to.meta.title || 'Sveve';
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
            
      const isArticle = to.fullPath.startsWith('/artikler/') && to.params.catchAll;
      if (!isArticle) {  // article pageview is tracked in CMS.vue (async title from Contentful)
        const pageviewEvent = {
          event: 'pageview',
          path: to.fullPath,
          page_title: to.meta.title, 
        };
        window.dataLayer.push(pageviewEvent);  // GTM/GA
      }
    }
  },
  computed: {
    showNavbar() {
      return this.$store.state.showNavbar;
    },
    showFooter() {
      return this.$store.state.showFooter;
    }    
  },
}
</script>
