<template>
    <div> 
        <h2 v-if="english">Retry intervals</h2>
        <h2 v-else>Retry intervaller</h2>
        <div v-if="english">
            If your server is not online or returns an error code, we will try to send the message again with increasing intervals. Time between each try:
        </div>
        <div v-else>
            Dersom deres server ikke svarer eller svarer med en feilkode, vil vi forsøke å sende meldingen på nytt med økende mellomrom. Tid mellom hvert forsøk:
        </div>        
        <div class="mt-2 ml-6">
            <div v-for="(int, i) in intervals" :key="i" class="flex">
                <span class="text-right mr-2 w-6">{{int.value}}</span><span v-if="english">{{int.unitEn}}</span><span v-else>{{int.unitNo}}</span>                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        english: Boolean,
    },    
    data() {
        return {
            intervals: [
                { value: '5', unitNo: 'sekunder', unitEn: 'seconds'},
                { value: '15', unitNo: 'sekunder', unitEn: 'seconds'},
                { value: '1', unitNo: 'minutt', unitEn: 'minute'},
                { value: '2', unitNo: 'minutter', unitEn: 'minutes'},
                { value: '5', unitNo: 'minutter', unitEn: 'minutes'},
                { value: '30', unitNo: 'minutter', unitEn: 'minutes'},
                { value: '1', unitNo: 'time', unitEn: 'hour'},
                { value: '2', unitNo: 'timer', unitEn: 'hours'},
                { value: '4', unitNo: 'timer', unitEn: 'hours'},
            ]
        }
    }
}
</script>