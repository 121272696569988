<template>
    <h2 v-if="english">Groups and recipients</h2>
    <h2 v-else>Grupper og mottakere</h2>
    <div v-if="english">
        Use groups to send messages to multiple recipients in one simple operation. 
    </div>
    <div v-else>
        Grupper kan brukes til å sende SMS til flere personer samtidig i en enkel operasjon. 
        Her følger operasjonene som kan gjøres med grupper og mottakere via HTTP GET.
    </div>

    <div class="pt-4">
        <span v-if="english">Base URL for all requests:</span>
        <span v-else>Basis-URL for alle kall:</span>
    </div>
    <div class="api-example mt-2"> 
        https://sveve.no/SMS/RecipientAdm?user=BRUKERNAVN&passwd=API-PASSORD&f=json2
    </div>
    <div class="text-sm  mb-4">
        <span v-if="english">Omit f=json to get plain text response</span>
        <span v-else>f=json kan unnlates dersom man ønsker kun tekst-retur</span>
    </div>

    <div>
        <div v-for="(c, i) in english ? commands_eng : commands" :key="i" class="pb-4">
            <div class="font-semibold">{{ c.name }}</div>
            <div class="border border-dashed border-gray-600 p-2 font-mono text-sm" style="overflow-wrap: break-word">..{{ c.params }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        english: Boolean,
    },    
    data() {
        return {
            commands: [
                { name: 'Opprette gruppe', params: '&cmd=add_group&group=Gruppenavn'},
                { name: 'Flytte gruppe', params: '&cmd=move_group&group=Gammel%20gruppe&new_group=Ny%20gruppe'},
                { name: 'Slette gruppe', params: '&cmd=delete_group&group=Gruppenavn'},
                { name: 'Liste grupper', params: '&cmd=list_groups'},
                { name: 'Liste mottakere i gruppe', params: '&cmd=list_recipients&group=Gruppenavn'},
                { name: 'Opprette mottaker', params: '&cmd=add_recipient&name=Donald%20Duck&number=98765432&group=Gruppenavn'},
                { name: 'Flytte mottaker', params: '&cmd=move_recipient&number=98765432&group=Gruppenavn&new_group=Ny%20gruppe'},
                { name: 'Slette mottaker', params: '&cmd=delete_recipient&number=98765432&group=Gruppenavn'},
            ],
            commands_eng: [
                { name: 'Create group', params: '&cmd=add_group&group=Group%20name'},
                { name: 'Move group', params: '&cmd=move_group&group=Old%20group&new_group=New%20group'},
                { name: 'Delete group', params: '&cmd=delete_group&group=Group%20name'},
                { name: 'List groups', params: '&cmd=list_groups'},
                { name: 'List group members', params: '&cmd=list_recipients&group=Group%20name'},
                { name: 'Create recipients', params: '&cmd=add_recipient&name=Donald%20Duck&number=98765432&group=Group%20name'},
                { name: 'Move recipient', params: '&cmd=move_recipient&number=98765432&group=Group%20name&new_group=New%20group'},
                { name: 'Delete recipient', params: '&cmd=delete_recipient&number=98765432&group=Group%20name'},
            ]            
        }
    }
}
</script>