import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import './css/tailwind.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { VueCookieNext } from 'vue-cookie-next';
import VueGtag from 'vue-gtag-next';
//import { VueReCaptcha } from 'vue-recaptcha-v3';


export const Settings = {
    app: {
        root: process.env.VUE_APP_WEB_ROOT
    },    
    /*google: {
        ga_id: 'UA-1526134-1',  
        gtm_id: 'GTM-PR4WW9'  
    },*/
    contentful: {
        space: '57ckoh6wm6t5',
        accessToken: 'NK_CXoYXO2GOCAVDd5PaC44qyJ7mO6lNmDw0CDeHSfI'    
    },
    server: {
        login: process.env.VUE_APP_LOGIN_URL,
        password: process.env.VUE_APP_PASSWORD_URL,
        api: process.env.VUE_APP_API_URL,
        sms_app: process.env.VUE_APP_SMS_APP
    },
    recaptcha: {
        siteKey: ''
    }
};

const store = createStore({
    state() {
        return {
            showNavbar: true,
            showFooter: true,
            products: [],
            trialOrder: {}
        }
    },
    mutations: {
        addProduct(state, id) {
            const i = state.products.indexOf(id);
            if (i === -1 && id.toUpperCase() !== 'BAS') {
                state.products.push(id);
            }
        },
        removeProduct(state, id) {
            const i = state.products.indexOf(id);
            if (i > -1) {
                state.products.splice(i, 1);
            }
        },
        removeAllProducts(state) {
            state.products = [];
        },
        setTrialOrder(state, trialOrder) {
            state.trialOrder = trialOrder;
        },
        clearTrialOrder(state) {
            state.trialOrder = {};
        }
    },
    actions: {
        hasProduct({ state }, id) {
            return state.products.indexOf(id) > -1;
        },
        orderProduct({ commit }, id) {
            commit('removeAllProducts');
            let ar = id.split(',');
            ar.forEach((p) => {
                commit('addProduct', p);
            });            
            router.push('/bestill');         
        }
    },
    getters: {
        products: state => {
            return state.products;
        },
        trialOrder: state => {
            return state.trialOrder;
        }
    }
});


const app = createApp(App);

app.use(router);

//app.use(VueSplide);
app.use(VueAxios, axios);
app.use(store);
app.use(VueCookieNext);

app.use(VueGtag, {
    property: {
      id: 'G-RGGVSS8M3G'
    }
});

/*app.use(VueReCaptcha, {
    siteKey: '6LdpXr4nAAAAACS4bQYkmOkGFPAHB61oPm27jvSy',
    loaderOptions: {
      useRecaptchaNet: true
    }
  })*/

app.mount('#app');
