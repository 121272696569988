<template>
    <div :class="{'h-screen': !pageRenderFinish}">
        <div v-if="pageRenderFinish">            

            <h1 class="pb-2 sm:pb-4 text-center">{{ list_title }}</h1>

            <!-- Article list -->            
            <div v-if="article_list.length || dynPageList.length" class="sm:flex">                
                <img class="hidden lg:block pr-20" src="/img/plane-blue-vert.svg" alt="">
                <div>
                    <div>
                        <div v-for="(article, i) in article_list" :key="i">
                            <div class="flex pb-2">
                                <img class="pr-3" src="/img/icon/tick-small.svg" alt=""><router-link :to="article.path">{{ article.title }}</router-link>
                            </div>                    
                        </div>
                    </div>
                    <div v-if="dynPageList.length"> 
                        <h1 class="py-6 text-center">Sider</h1>
                        <div v-for="(page, p) in dynPageList" :key="p">
                            <div class="flex pb-2">
                                <img class="pr-3" src="/img/icon/tick-small.svg" alt=""><router-link :to="page.path">{{ page.title }}</router-link>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>

            <!-- Article -->
            <div class="sm:mx-20 lg:mx-40">
                <h1 class="pb-2 sm:pb-4">{{ title }}</h1>
                <img class="py-4" v-if="image_top" v-bind:src="image_top" :width="image_top_width" :height="image_top_height" v-bind:alt="image_top_alt">
                <div v-html="content" class="article-content"></div>
                <div v-if="orderBtnTxt" class="py-4"><button @click="orderBtnClick" class="btn btn-blue">{{ orderBtnTxt }}</button></div>
                <img class="py-4" v-if="image_bottom" v-bind:src="image_bottom" :width="image_bottom_width" :height="image_bottom_height" v-bind:alt="image_bottom_alt">
            </div>
        </div>
    </div>
    <end-page v-if="pageRenderFinish" />
</template>

<script>
import { Settings } from '@/main.js';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import * as contentful from 'contentful';

export default {
    name: 'CMS',
    data() {
        return {            
            title: '',
            list_title: '',
            content: '',
            image_top: '',
            image_top_alt: '',
            image_top_width: '',
            image_top_height: '',
            image_bottom: '',
            image_bottom_alt: '',
            image_bottom_width: '',
            image_bottom_height: '',            
            article_list: [],
            orderBtnTxt: '',
            orderProducts: [],
            pageRenderFinish: false,
            dynPageList: [],
        }
    },
    methods: {        
        getContent(artPath, listArticles) {

            const client = contentful.createClient({
                space: Settings.contentful.space, 
                accessToken: Settings.contentful.accessToken
            });   

            client.getEntries({ content_type: 'artikkel', select: 'sys.id,fields.path,fields.title,fields.sortOrder,fields.showInList', order: 'fields.sortOrder' }).then((entries) => {   // get all article id's
                let notFound = !listArticles;
                for (let i = 0; i < entries.items.length; i++) {                    
                    let item = entries.items[i];
                    if (listArticles && item.fields.showInList === true) {
                        let path = this.$route.path; 
                        if (!path.endsWith('/')) {
                            path += '/';
                        }     
                        path += item.fields.path;             
                        this.article_list.push({path: path, title: item.fields.title});
                    }
                    else if (item.fields.path === artPath) {
                        notFound = false;
                        client.getEntry(item.sys.id).then((entry) => {  // get article content

                            document.title = entry.fields.title;
                            this.title = entry.fields.title;
                            document.querySelector('meta[name="description"]').setAttribute("content", entry.fields.metaDescription);

                            const pageviewEvent = {
                                event: 'pageview',
                                path: this.$route.path,
                                page_title: entry.fields.title,  
                            };
                            window.dataLayer.push(pageviewEvent);  // GTM/GA                            

                            this.content = documentToHtmlString(entry.fields.content);

                            if (entry.fields.orderBtn) {
                                let btn = entry.fields.orderBtn.split('|');
                                this.orderBtnTxt = btn[0].trim();
                                if (btn.length > 1) {                             
                                    this.orderProducts = btn[1].trim().split(',');
                                }
                            }

                            if (entry.fields.image_top) {
                                this.image_top = entry.fields.image_top.fields.file.url;
                                this.image_top_alt = entry.fields.image_top.fields.title;
                                let dim = entry.fields.image_top.fields.file.details.image;                                
                                this.image_top_width = dim.width;
                                this.image_top_height = dim.height;
                            }
                            if (entry.fields.image_bottom) {
                                this.image_bottom = entry.fields.image_bottom.fields.file.url;
                                this.image_bottom_alt = entry.fields.image_bottom.fields.title;
                                let dim = entry.fields.image_bottom.fields.file.details.image;                                
                                this.image_bottom_width = dim.width;
                                this.image_bottom_height = dim.height;                                
                            }

                            this.pageRenderFinish = true;
                        });
                        break;
                    }
                }
                if (listArticles) {
                    this.list_title = 'Artikler';
                    this.pageRenderFinish = true;
                }
                else if (notFound) {
                    this.title = 'Oops!';
                    this.content = 'Denne siden finnes ikke...';
                }
            });

            // get dynamic landing pages
            if (listArticles) {
                client.getEntries({ content_type: 'landingpage_dynamic', select: 'fields.path,fields.title,fields.sortOrder,fields.showInList', order: 'fields.sortOrder' }).then(entries => {  
                    entries.items.forEach(item => {
                        if (item.fields.showInList) {
                            this.dynPageList.push({path: '/sider/' + item.fields.path, title: item.fields.title});
                        }
                    });
                });
            }

        },
        orderBtnClick: function() {
            this.orderProducts.forEach((p) => {
                if (p && p.length === 3) {
                    this.$store.commit('addProduct', p, false);
                }
            });
            this.$router.push('/bestill');
        }
    },
    created: function() {
        let p = this.$route.path.substring(1).split('/');        
        if (!p[p.length-1]) {
            p.splice(p.length-1);
        }        
        let artPath = p[p.length-1];
        let listArticles = p.length === 1;
        this.getContent(artPath, listArticles);        
    }
}
</script>