<template >
    <div :class="{'h-screen': !pageRenderFinish}">
        <div v-if="pageRenderFinish">

            <div class="flex pt-4 sm:pt-10">
                <div class="sm:w-1/2">
                    <div class="text-2xl sm:text-3xl pb-4 text-gray-800 leading-none">{{ subHeading }}</div>
                    <h1 class="text-3xl sm:text-5xl font-bold leading-none">{{ heading }}</h1>                    
                    <div class="py-8" v-html="headingText"></div>
                    <router-link to="/bestill" class="btn btn-blue">{{ freeTrialBtn }}</router-link>
                    <router-link to="/kontakt" class="btn btn-gray hover-arrow ml-2">{{ contactSales }}</router-link>
                </div>
                <div class="hidden sm:block">
                    <img class="pl-10" v-bind:src="heroImage" :width="heroImage_width" :height="heroImage_height" v-bind:alt="heroImage_alt">
                </div>
            </div>

            <hr class="line-hor mt-10" />            
            <div class="flex flex-wrap h-24 overflow-x-hidden overflow-y-hidden justify-evenly py-8">
                <div v-for="(logo, i) in customerLogos" :key="i">
                    <img class="px-2 sm:px-6 h-6 sm:h-8 mb-20" :src="logo" alt="">
                </div>
            </div>
            <hr class="line-hor" />

            <div class="flex flex-wrap justify-evenly pt-10 sm:pt-20">
                <SloganCard class="sm:w-1/4" :heading="slogan1Heading" :text="slogan1Text">
                    <Image :attr="{ src:'/img/header-2.svg', width: 112, height: 112 }" _class="mx-auto" />
                </SloganCard>
                <SloganCard class="sm:w-1/4" image="/img/header-2.svg" alt="" :heading="slogan2Heading" :text="slogan2Text">
                    <Image :attr="{ src:'/img/header-1.svg', width: 112, height: 112 }" _class="mx-auto"  />
                </SloganCard>
                <SloganCard class="sm:w-1/4" image="/img/header-3.svg" alt="" :heading="slogan3Heading" :text="slogan3Text">
                    <Image :attr="{ src:'/img/header-3.svg', width: 112, height: 112 }" _class="mx-auto"  />
                </SloganCard>
            </div>

            <div class="flex justify-end pt-10 pb-10 sm:pb-1">
                <img src="/img/plane-blue-left.svg" alt="">
            </div>

            <div class="sm:text-center sm:px-10 sm:py-16">
                <div class="text-gray-900 text-2xl sm:text-4xl" v-html="section1Heading"></div>
                <div class="pt-4 pb-6" v-html="section1Text"></div>
                <div class="text-center">
                    <router-link to="/bestill" class="btn btn-blue">{{ seeProductsBtn }}</router-link>
                </div>
            </div>

            <div>
                <img class="hidden sm:block" src="/img/line-1.svg" alt=""><br>
                <img src="/img/plane-blue-right.svg" alt="">
            </div>

            <div class="sm:px-10 py-10">
                <div class="sm:text-center font-semibold text-gray-900 text-2xl sm:text-4xl pb-10 sm:pb-20" v-html="section2Heading"></div>

                <div class="flex flex-wrap justify-between px-2 pb-10 sm:pb-20">
                    <div class="sm:w-1/2 pb-6">
                        <div class="section-bullet">01.</div>
                        <div class="text-2xl sm:text-4xl text-gray-900 leading-tight pb-2" v-html="section2aHeading"></div>
                        <div v-html="section2aText"></div>
                    </div>
                    <div class="sm:w-1/2 sm:pl-10">
                        <img src="/img/illustration-1.svg" alt="">
                    </div>
                </div>

                <div class="flex flex-wrap-reverse justify-between py-10 bg-red-100 bg-opacity-50">
                    <div class="sm:w-1/2 sm:pr-10">
                        <img src="/img/illustration-2.svg" alt="">
                    </div>            
                    <div class="sm:w-1/2 pb-6 px-2">
                        <div class="section-bullet">02.</div>
                        <div class="text-2xl sm:text-4xl text-gray-900 leading-tight pb-2" v-html="section2bHeading"></div>
                        <div v-html="section2bText"></div>
                        <div class="flex justify-end">
                            <img class="h-20" src="/img/plane-green-left2.svg" alt="">
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap justify-between pt-10 sm:pt-20">
                    <div class="sm:w-1/2 pb-6 px-2">
                        <div class="section-bullet">03.</div>
                        <div class="text-2xl sm:text-4xl text-gray-900 leading-tight pb-2" v-html="section2cHeading"></div>
                        <div v-html="section2cText"></div>
                    </div>
                    <div class="sm:w-1/2 sm:pl-10">
                        <img src="/img/illustration-3.svg" alt="">
                    </div>
                </div>                
            </div>

            <div class="flex justify-evenly flex-wrap my-10 sm:my-16 pt-40 sm:py-20 pl-4 sm:pl-10 pb-4 bg-cover -mx-4 sm:-mx-6 lg:-mx-12 xl:-mx-24" style="background-image: url('/img/background-blue-plane.svg'); background-repeat: no-repeat; background-color: #2B44DB">
                <div class="text-white text-2xl sm:text-4xl" v-html="bigSlogan"></div>
                <div class="mt-6 pb-6">
                    <router-link to="/bestill" class="btn btn-orange">{{ bigSloganBtn }}</router-link>
                </div>
            </div>

            <div class="mb-4 sm:mb-10 bg-white">
                <div class="mb-2 sm:mb-4 text-center text-2xl sm:text-3xl font-semibold text-gray-900" v-html="testimonialHeading"></div>
                <div class="flex flex-wrap justify-center">
                    <div class="mb-6 sm:m-4 p-4 sm:p-6 w-full sm:w-2/5 shadow-lg" v-for="(testimonial, i) in testimonials" :key="i">
                        <img class="h-6 sm:h-8 mb-3" src="/img/icon/quote-blue-big.svg" alt="">
                        <div class="italic">{{testimonial.text}}</div>
                        <div class="font-semibold mt-2 text-gray-800">{{testimonial.sign}}</div>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap bg-contain sm:bg-cover p-4 sm:p-10 mb-10" style="background-image: url('/img/background-rectangle.svg')">
                <div class="sm:w-1/2 sm:pr-20 lg:pr-40">
                    <div class="text-2xl leading-snug pb-4 text-gray-900 font-semibold">{{ section3Heading }}</div>
                    <div class="pb-4 sm:pb-10" v-html="section3Text"></div>
                    <img class="h-16 sm:h-20 sm:pl-10" src="/img/plane-green-right.svg" alt="">
                </div>
                <div class="bg-white rounded-xl shadow-lg py-6 sm:py-10 px-4 sm:px-10 w-full sm:w-2/5">
                    <div class="font-semibold text-gray-800 text-xl text-center">{{ contactHeading }}</div>
                    <div class="relative"> 
                        <form class="w-full flex flex-col" @submit.prevent="submitContact">
                            <InputField type="text" v-model="contactForm.name" image="/img/icon/name.svg" :placeholder="contactName" required />
                            <InputField type="email" v-model="contactForm.email" image="/img/icon/email.svg" :placeholder="contactEmail" required />
                            <TextArea v-model="contactForm.msg" _class="h-48 sm:h-32" image="/img/icon/message.svg" :placeholder="contactMsg" required />
                            <button class="btn btn-blue mt-4">{{ contactBtn }}</button>                    
                            <div class="text-center pt-2 animate-pulse h-4" v-html="contactSubmittedMsg"></div>
                        </form>                            
                        <img src="/img/plane-blue-vert.svg" alt="" class="hidden sm:block absolute object-left" style="right:-110px; top:-10px;">
                    </div>
                </div>
            </div>
                
        </div>
    </div>
    <end-page v-if="pageRenderFinish" />
</template>

<script>
import { Settings } from '@/main.js';
import SloganCard from '@/components/SloganCard.vue';
import Image from '@/components/Image.vue';
import InputField from '@/components/InputField.vue';
import TextArea from '@/components/TextArea.vue';
import * as contentful from 'contentful';
import axios from 'axios';


export default {
    components: {
        SloganCard,
        Image,
        InputField,
        TextArea
    },
    data() {
        return {
            contactForm: {
                name: '', email: '', msg: '',
            },      
            contactSubmittedMsg: '',
            pageRenderFinish: false,
            heroImage: '', heroImage_width: 0, heroImage_height: 0, heroImage_alt: '',

            // -------- Texts / Content -----------------
            heading: '', subHeading: '', headingText: '',
            freeTrialBtn: '', contactSales: '',
            slogan1Heading: '', slogan1Text: '',
            slogan2Heading: '', slogan2Text: '',
            slogan3Heading: '', slogan3Text: '',                        
            section1Heading: '', section1Text: '', seeProductsBtn: '',
            section2Heading: '',
            section2aHeading: '', section2aText: '',
            section2bHeading: '', section2bText: '',
            section2cHeading: '', section2cText: '',
            bigSlogan: '', bigSloganBtn: '',
            testimonialHeading: '', testimonials: [],
            section3Heading: '', section3Text: '',
            contactHeading: '', contactName: '', contactEmail: '', contactMsg: '', contactBtn: '', contactSubmittedOk: '', contactSubmittedErr: '',
            customersHeading: '',
            customerLogos: [
                '/img/customers/fjord1_g.svg',
                '/img/customers/veidekke_g.svg',
                '/img/customers/dyreparken_g.svg',
                '/img/customers/norli_g.svg',
                '/img/customers/lyse_g.svg',
                '/img/customers/bergenkommune_g.svg',                
                '/img/customers/ntnu_g.svg',                                                                
            ],
        }
    },
    created: function() {
        this.getContent();
    },    
    methods: {
        getContent: function() {
            const cmsClient = contentful.createClient({
                space: Settings.contentful.space, 
                accessToken: Settings.contentful.accessToken
            });            
            cmsClient.getEntries({ content_type: 'forside', 'fields.topDomain': 'no' }).then((entries) => {                 
                if (entries.items && entries.items.length) {
                    let contentJson = entries.items[0].fields.content;
                    for (let prop in contentJson) {
                        this[prop] = contentJson[prop];
                    }
                    let heroImg = entries.items[0].fields.heroImg;
                    if (heroImg) {
                        this.heroImage = heroImg.fields.file.url;
                        this.heroImage_alt = heroImg.fields.title;
                        let dim = heroImg.fields.file.details.image;                                
                        this.heroImage_width = dim.width;
                        this.heroImage_height = dim.height;                    
                    }
                }
                this.pageRenderFinish = true;
            });
        },
        submitContact: function() {
            const options = {
                url: Settings.server.api + '/contact',
                method: 'POST',
                data: this.contactForm,
                timeout: 3000          
            };
            axios(options).then(response => {  
                this.contactSubmittedMsg = response.data.success ? this.contactSubmittedOk : this.contactSubmittedErr;
                setTimeout(() => this.contactSubmittedMsg = '', 4000);
                if (response.data.success) {
                    this.contactForm.name = '';
                    this.contactForm.email = '';
                    this.contactForm.msg = '';
                }
            }).catch(error => {
                console.log(error);
                this.contactSubmittedMsg = this.contactSubmittedErr;
            });
        }
    }
}
</script>