<template>
    <div class="my-6 mx-auto text-center">
        <div class="mb-6 w-2/3 max-w-xs mx-auto">
            <img :src="logo" alt="logo">
        </div>  
        <div v-if="finish" class="pt-2">
            <div v-if="checkedGroups.length">
                Takk for påmeldingen
                <br><br>
                <a href="https://movar.no/miljøbilen">Les mer</a> om hva du kan levere og annen informasjon om Miljøbilen
            </div>
            <div v-else>
                Du er nå meldt ut av SMS-varslingen
            </div>
        </div>      
        <div v-else-if="!groups.length">            
            <label v-if="!otpSent">Skriv inn ditt mobilnummer</label>
            <label v-else>Skriv inn koden du fikk til din telefon</label>
            <div class="flex justify-center mt-3">
                <form @submit.prevent="submit(false)">
                    <input v-if="!otpSent" type="tel" class="input-text" v-model="mobile" placeholder="Mobilnummer">
                    <input v-else type="text" class="input-text" v-model="otp" placeholder="OTP">                
                    <button type="submit" class="btn btn-gray button">Logg inn</button>
                </form>
            </div>
        </div>        
        <div v-else> 
            <div v-html="infoText" class="px-2 text-left my-6 flex justify-center"></div>
            <div class="my-4">
                <button @click="submit(true)" class="btn btn-gray button">Lagre</button>
            </div>  
            <div class="flex justify-center">               
                <div class="flex flex-col">
                    <div v-for="(group, i) in groups" :key="i" class="text-left">                        
                        <div v-if="group.secondPrefix" class="leading-tight mb-2">
                            <input type="checkbox" :id="'cb'+i" :value="group.secondPrefix" v-model="checkedGroups">
                            <label :for="'cb'+i" class="ml-2">{{ group.secondPrefix }} {{ group.info }}</label>                        
                        </div>
                    </div>
                </div>             
            </div>
            <div class="mt-4">
                <button @click="submit(true)" class="btn btn-gray button">Lagre</button>
            </div>            
        </div>  
        <div class="inline-block mt-4 px-10 py-1 sm:w-1/2 bg-blue-200" v-if="message">{{ message }}</div>     
    </div>
</template>

<script>
import axios from 'axios';
import { Settings } from '@/main.js';

export default {
    data() {
        return {
            otpSent: false,
            shortnumber: '',
            prefix: '',
            mobile: '',
            otp: '',
            groups: [],
            infoText: '',
            logo: '',
            key: '',
            checkedGroups: [],
            message: '',
            finish: false,
        }
    },
    created() {
        this.prefix = this.$route.params.prefix;
        this.shortnumber = this.$route.params.shortnumber;
        this.submit(false);  // get logo
    },
    methods: {
        submit: function(saveGroups) {
            this.message = '';
            let params = '?prefix=' + this.prefix + '&shortnumber=' + this.shortnumber + '&mob=' + this.mobile + '&otp=' + this.otp;
            if (saveGroups) {
                params += '&groups=' + this.checkedGroups;
            }
            axios.get(Settings.server.api + '/prefixportal' + params).then((res) => {
                if (saveGroups) {          
                    this.finish = true;        
                }
                else if (res.data.logo) {
                    this.logo = res.data.logo;
                }
                else if (res.data.groups) {
                    this.groups = res.data.groups;
                    for (let i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].selected) {
                            this.checkedGroups.push(this.groups[i].secondPrefix);
                        }
                    }
                    this.infoText = res.data.infoText;
                }
                else {
                    this.otpSent = true;
                }
            }).catch(() => {
                this.message = 'Feil';
            });
        },
    }
}
</script>

<style scoped>
    .button {
        @apply border-gray-500 border m-2 whitespace-no-wrap bg-white;
    }
</style>