<template>
    <div class="p-6 sm:p-8 text-center shadow-lg bg-white mb-4">
        <slot></slot>
        <div class="py-4 text-gray-900 text-xl font-semibold">{{ heading }}</div>
        <div v-html="text"></div>
    </div>
</template>

<script>
export default {
    name: 'SloganCard',
    props: ['heading', 'text']
}
</script>