<template>

    <div class="service-option">
        
        <div class="flex flex-wrap sm:flex-no-wrap justify-between">
            <div class="pb-4">
                <div class="font-semibold pb-2 text-2xl">{{ heading }}</div>
                <div v-html="desc"></div>
            </div>
            <ServicePrice :serviceInfo="serviceInfo" :smsPrices="smsPrices" :isOption="true" :plan="plan" />
        </div>

        <button v-if="isTrial" class="btn btn-blue btn-small mr-4" @click="orderProduct(serviceInfo.shortName)">Prøv gratis</button>
        <router-link v-if="moreInfoLink" :to="moreInfoLink" class="btn btn-gray hover-arrow">Les mer</router-link>
    </div>
    
</template>

<script>
import { mapActions } from 'vuex';
import ServicePrice from '@/components/ServicePrice.vue';

export default {
    components: {
        ServicePrice
    },
    props: {
        serviceInfo: Object,
        smsPrices: Array,
        isTrial: Boolean,
        heading: String,
        desc: String,
        moreInfoLink: String,
        plan: String,
    },
    methods: {
        ...mapActions([
            'orderProduct'
        ])
    }    
}
</script>