<template> 
    <div :class="{'h-screen': !pageRenderFinish}">
        <div v-if="pageRenderFinish">  
            <div v-if="notFound">
                Siden finnes ikke
            </div>
            <div v-else>
                <div class="flex flex-wrap pt-4 sm:pt-10 gap-y-8">
                    <div class="sm:w-1/2">
                        <div class="text-2xl sm:text-3xl pb-4 text-gray-800 leading-none">{{ subHeading }}</div>
                        <h1 class="text-3xl sm:text-5xl font-bold leading-none">{{ heading }}</h1>                    
                        <div class="py-8" v-html="headingText"></div>
                        <button v-if="freeTrialBtn" class="btn btn-blue" @click="orderProduct()">{{ freeTrialBtn }}</button>
                        <router-link v-if="contactSales" to="/kontakt" class="btn btn-gray hover-arrow ml-2">{{ contactSales }}</router-link>
                    </div>

                    <div class="sm:w-1/2 mx-auto">
                        <div v-if="directTrialOrder" class="border rounded shadow px-10 py-6 max-w-md bg-white mx-auto">
                            <div class="mb-3">Bestill en <span class="font-semibold">gratis</span> prøvekonto</div>
                            <form @submit.prevent="submitTrialOrder()"> 
                                <InputField type="text" v-model="orderForm.name" image="/img/icon/house.svg" placeholder="Firmanavn" required />
                                <InputField type="text" v-model="orderForm.contact" image="/img/icon/name.svg" placeholder="Kontaktperson" required />
                                <InputField type="email" v-model="orderForm.email" image="/img/icon/email.svg" placeholder="E-postadresse" required />
                                <InputField type="tel" v-model="orderForm.phone" image="/img/icon/phone.svg" placeholder="Mobilnummer" />                                
                                <div class="w-full text-right">
                                    <button class="mt-2 btn btn-blue px-12">Bestill</button>
                                </div>
                            </form>
                            <div v-if="trialOrderErrorMsg" class="text-red-600 font-semibold text-sm text-center mt-2">{{ trialOrderErrorMsg }}</div>
                        </div>
                        <div v-else class="hidden sm:block">
                            <Image class="pl-10" :attr="imgHeroAttr" />
                        </div>
                    </div>
                </div>

                <hr class="line-hor mt-10" />            
                <div class="flex flex-wrap h-24 overflow-x-hidden overflow-y-hidden justify-evenly py-8">
                    <div v-for="(logo, i) in customerLogos" :key="i">
                        <img class="px-2 sm:px-6 h-6 sm:h-8 mb-20" :src="logo" alt="">
                    </div>
                </div>
                <hr class="line-hor" />              
                
                <div class="mt-10 p-10" style="background-color: #F6F6F6">
                    <div class="pb-6 text-center font-bold text-gray-800 text-2xl">Funksjonalitet og egenskaper</div>
                    <div class="flex flex-wrap justify-between">
                        <div class="mb-6 sm:mb-0">
                            <div class="flex flex-wrap">
                                <div v-for="(feature, i) in features" :key="i" class="w-full lg:w-1/2 lg:pr-6 py-2">
                                    <div class="flex"><span class="w-8 mt-1"><img src="/img/icon/tick.svg" alt=""></span><span class="w-full" v-html="feature"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                

                <div class="flex flex-wrap justify-evenly pt-10 sm:pt-20">
                    <SloganCard class="sm:w-1/4" :heading="slogan1Heading" :text="slogan1Text">
                        <Image :attr="imgTriple1Attr[0]" class="mx-auto" />
                    </SloganCard>
                    <SloganCard class="sm:w-1/4" :heading="slogan2Heading" :text="slogan2Text">
                        <Image :attr="imgTriple1Attr[1]" class="mx-auto" />
                    </SloganCard>
                    <SloganCard class="sm:w-1/4" :heading="slogan3Heading" :text="slogan3Text">
                        <Image :attr="imgTriple1Attr[2]" class="mx-auto" />
                    </SloganCard>
                </div>

                <div class="flex justify-end pt-10 pb-10 sm:pb-1">
                    <img src="/img/plane-blue-left.svg" alt="">
                </div>

                <div class="sm:text-center sm:px-10 sm:py-16">
                    <div class="text-gray-900 text-2xl sm:text-4xl" v-html="section1Heading"></div>
                    <div class="pt-4 pb-6" v-html="section1Text"></div>
                    <div class="text-center">
                        <button class="btn btn-blue" @click="orderProduct()">{{ seeProductsBtn }}</button>
                    </div>
                </div>             
                
                <div>
                    <img class="hidden sm:block" src="/img/line-1.svg" alt=""><br>
                    <img src="/img/plane-blue-right.svg" alt="">
                </div>         
                
                <div class="sm:px-10 py-10">
                    <div class="sm:text-center font-semibold text-gray-900 text-2xl sm:text-4xl pb-10 sm:pb-20" v-html="section2Heading"></div>

                    <div class="flex flex-wrap justify-between px-2 pb-10 sm:pb-20">
                        <div class="sm:w-1/2 pb-6">
                            <div class="section-bullet">01.</div>
                            <div class="text-2xl sm:text-4xl text-gray-900 leading-tight pb-2" v-html="section2aHeading"></div>
                            <div v-html="section2aText"></div>
                        </div>
                        <div class="sm:w-1/2 sm:pl-10">
                            <Image :attr="imgTriple2Attr[0]" />
                        </div>
                    </div>

                    <div class="flex flex-wrap-reverse justify-between py-10 bg-red-100 bg-opacity-50">
                        <div class="sm:w-1/2 sm:pr-10">
                            <Image :attr="imgTriple2Attr[1]" />
                        </div>            
                        <div class="sm:w-1/2 pb-6 px-2">
                            <div class="section-bullet">02.</div>
                            <div class="text-2xl sm:text-4xl text-gray-900 leading-tight pb-2" v-html="section2bHeading"></div>
                            <div v-html="section2bText"></div>
                            <div class="flex justify-end">
                                <img class="h-20" src="/img/plane-green-left2.svg" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between pt-10 sm:pt-20">
                        <div class="sm:w-1/2 pb-6 px-2">
                            <div class="section-bullet">03.</div>
                            <div class="text-2xl sm:text-4xl text-gray-900 leading-tight pb-2" v-html="section2cHeading"></div>
                            <div v-html="section2cText"></div>
                        </div>
                        <div class="sm:w-1/2 sm:pl-10">
                            <Image :attr="imgTriple2Attr[2]" />
                        </div>
                    </div>                
                </div>   
                
                <div class="flex justify-evenly flex-wrap my-10 sm:my-16 pt-40 sm:py-20 pl-4 sm:pl-10 pb-4 bg-cover -mx-4 sm:-mx-6 lg:-mx-12 xl:-mx-24" style="background-image: url('/img/background-blue-plane.svg'); background-repeat: no-repeat; background-color: #2B44DB">
                    <div class="text-white text-2xl sm:text-4xl" v-html="bigSlogan"></div>
                    <div class="mt-6 pb-6">
                        <button class="btn btn-orange" @click="orderProduct()">{{ bigSloganBtn }}</button>
                    </div>
                </div>                

                <div class="mb-4 sm:mb-10 bg-white">
                    <div class="mb-2 sm:mb-4 text-center text-2xl sm:text-3xl font-semibold text-gray-900" v-html="testimonialHeading"></div>
                    <div class="flex flex-wrap justify-center">
                        <div class="mb-6 sm:m-4 p-4 sm:p-6 w-full sm:w-2/5 shadow-lg" v-for="(testimonial, i) in testimonials" :key="i">
                            <img class="h-6 sm:h-8 mb-3" src="/img/icon/quote-blue-big.svg" alt="">
                            <div class="italic">{{testimonial.text}}</div>
                            <div class="font-semibold mt-2 text-gray-800">{{testimonial.sign}}</div>
                        </div>
                    </div>
                </div>

                <div class="bg-contain sm:bg-cover p-4 sm:p-10 mb-10" style="background-image: url('/img/background-rectangle.svg')">    
                    <div class="text-2xl leading-snug pb-4 text-gray-900 font-semibold">{{ section3Heading }}</div>
                    <div class="pb-4 sm:pb-10" v-html="section3Text"></div>
                    <img class="h-16 sm:h-20 sm:pl-10" src="/img/plane-green-right.svg" alt="">      
                </div>

            </div>        
        </div>
    </div>
    <end-page v-if="pageRenderFinish" />
</template>

<script> 
import axios from 'axios';
import { Settings } from '@/main.js';
import * as contentful from 'contentful';
import SloganCard from '@/components/SloganCard.vue';
import Image from '@/components/Image.vue';
import InputField from '@/components/InputField.vue';

export default {
    components: {
        Image,
        SloganCard,
        InputField,
    },
    data() {
        return {
            notFound: false,
            pageRenderFinish: false,
            directTrialOrder: false,

            imgHeroAttr: {},
            imgTriple1Attr: [{},{},{}],
            imgTriple2Attr: [{},{},{}],
            orderProducts: [], 

            // -------- Texts / Content -----------------
            heading: '', subHeading: '', headingText: '',
            freeTrialBtn: '', contactSales: '',
            slogan1Heading: '', slogan1Text: '',
            slogan2Heading: '', slogan2Text: '',
            slogan3Heading: '', slogan3Text: '',      
            section1Heading: '', section1Text: '', seeProductsBtn: '',         
            section2Heading: '',
            section2aHeading: '', section2aText: '',
            section2bHeading: '', section2bText: '',
            section2cHeading: '', section2cText: '',        
            bigSlogan: '', bigSloganBtn: '',    
            testimonialHeading: '', testimonials: [],
            section3Heading: '', section3Text: '',
            customersHeading: '',
            customerLogos: [
                '/img/customers/fjord1_g.svg',
                '/img/customers/veidekke_g.svg',
                '/img/customers/dyreparken_g.svg',
                '/img/customers/norli_g.svg',
                '/img/customers/lyse_g.svg',
                '/img/customers/bergenkommune_g.svg',                
                '/img/customers/ntnu_g.svg',                                                                
            ],       
            features: [],      
            orderForm: {
                name: '', contact: '', email: '', phone: '', products: []
            },
            trialOrderErrorMsg: '',
        }
    },
    created() {
        let p = this.$route.path.substring(1).split('/');
        if (p.length > 1 && p[1].length) {
            this.getContent(p[1]);
        }
        else {
            this.notFound = true;
        }
    },
    mounted() {

    },
    methods: {
        getContent: function(path) {
            
            const client = contentful.createClient({
                space: Settings.contentful.space, 
                accessToken: Settings.contentful.accessToken
            });  

            client.getEntries({ content_type: 'landingpage_dynamic', 'fields.path': path }).then(entries => {                 
                if (entries.items.length) {
                    const entry = entries.items[0];

                    document.title = entry.fields.title;
                    document.querySelector('meta[name="description"]').setAttribute('content', entry.fields.metaDescription);

                    let contentJson = entry.fields.content;
                    for (let prop in contentJson) {
                        this[prop] = contentJson[prop];
                    }

                    if (this.freeTrialBtn) {
                        let trialBtn = this.freeTrialBtn.split('|');
                        this.freeTrialBtn = trialBtn[0].trim();
                        if (trialBtn.length > 1) {  // add products
                            this.orderProducts = trialBtn[1].trim().split(',');
                        }
                    }

                    this.populateImage(this.imgHeroAttr, entry.fields.heroImg?.fields);

                    if (entry.fields.tripleImg1) {
                        entry.fields.tripleImg1.forEach((img, t) => {
                            this.populateImage(this.imgTriple1Attr[t], img.fields);
                        });
                    }             
                    
                    if (entry.fields.tripleImg2) {
                        entry.fields.tripleImg2.forEach((img, t) => {
                            this.populateImage(this.imgTriple2Attr[t], img.fields);
                        });
                    }      
                    
                    this.directTrialOrder = entry.fields.orderTrialDirect;                    
                    this.$store.state.showNavbar = entry.fields.showHeader;
                    this.$store.state.showFooter = entry.fields.showFooter;
                }
                else {
                    this.notFound = true;
                }

                this.pageRenderFinish = true; 
            });
        },
        populateImage: function(imgAttr, imgData) {
            if (imgAttr && imgData) {
                imgAttr.src = imgData.file.url;
                imgAttr.alt = imgData.title;
                let dim = imgData.file.details.image;                                
                imgAttr.width = dim.width;
                imgAttr.height = dim.height; 
            }
        },        
        orderProduct: function() {  // goto trial order page
            this.orderProducts.forEach((p) => {
                if (p && p.length === 3) {
                    this.$store.commit('addProduct', p, false);
                }
            });
            this.$router.push('/bestill');
        },
        submitTrialOrder: function() {                
            this.orderForm.products = this.orderProducts;      
            const options = {
                url: Settings.server.api + '/signup',
                method: 'POST',
                data: this.orderForm,
                timeout: 3000
            };
            axios(options).then(response => {                   
                if (response.data.success) {   
                    response.data.transaction.event = 'purchase';   // custom event name for GTM trigger
                    const trialOrder = {
                        autoApproved: response.data.autoApproved,
                        transactionObject: response.data.transaction
                    }                                    
                    this.$store.commit('setTrialOrder', trialOrder);
                    this.$router.push('/bestill/3');
                }
                else {                    
                    this.trialOrderErrorMsg = 'Det oppstod en feil';
                }
            }).catch(error => {
                console.log(error);
                this.trialOrderErrorMsg = error.message;
            });      
        }             
    }
}

</script>