<template>
    <div class="relative">
        <img :src="image" class="absolute" v-bind:style="{ marginTop: '18px', marginLeft: '10px' }" alt="">
        <textarea :placeholder="placeholder" :style="{ paddingLeft: '40px' }" class="input-text w-full resize-none" :class="_class" v-model="inputVal" :required="required"></textarea>
    </div>
</template>

<script>
export default {
    name: 'TextArea',
    props: ['image', 'placeholder', '_class', 'modelValue', 'required'],
    computed: {
        inputVal: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit('update:modelValue', val);
            }
        }
    }     
}
</script>