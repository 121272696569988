<template>
    <div class="text-center">
        <br><br>
        <h1 class="text-2xl">Oops!</h1>
        <h2 class="text-xl mt-4">Denne siden finnes ikke...</h2>
        <br><br><br><br>
    </div>
</template>

<script>
export default {
    
}
</script>