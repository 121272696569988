<template>
    <div v-if="serviceInfo" class="sm:pl-8 pb-4 sm:text-right">

        <div v-if="basisInfo" class="font-bold">
            Grunnpakke: <span class="text-2xl">{{ basisInfo.price }}</span>,-/mnd
        </div>

        <div class="font-bold text-2xl">
            <span v-if="isOption">+</span><span :class="{'text-4xl': !isOption || basisInfo}">{{ price }}</span>,-/mnd
        </div>

        <div class="font-semibold">
            <span v-if="startupPrice === 0">Gratis etablering</span>
            <span v-else>{{ startupPrice }},- etablering</span>
        </div>
        <div class="text-sm mt-2" v-if="smsPrices.length">SMS fra {{ smsPriceFrom }} øre</div>
        <div class="text-sm whitespace-nowrap">eks. mva, faktureres {{ planDesc }}</div>
    </div>
</template>

<script>
export default {
    props: {
       serviceInfo: Object,
       smsPrices: Array,
       isOption: Boolean,
       basisInfo: Object,
       plan: String,
    },
    computed: {
        price() {
            return this.plan === 'month' ? this.serviceInfo.priceMonthly : this.serviceInfo.price;
        },
        planDesc() {
            return this.plan === 'month' ? 'månedlig' : 'årlig';
        },
        startupPrice: function() {
            let p = this.serviceInfo.startupPrice;
            if (this.basisInfo) {
                p += this.basisInfo.startupPrice ;
            }
            return p;
        },
        smsPriceFrom: function() {
            return this.smsPrices[this.smsPrices.length-1].price;
        }
    }
}
</script>