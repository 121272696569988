<template>
    <div>
        <h1>Informasjonskapsler/cookies på våre nettsider</h1>

        <p>
            En informasjonskapsel, ofte kalt cookie, er en liten tekstfil som lastes ned og lagres på din datamaskin når du åpner en nettside. Dette er en standard teknologi som de aller fleste nettsider bruker. Hensikten med cookies er å forenkle og forbedre surfeopplevelsen for våre besøkende, samt å gi oss statistikk som gjør at vi kan utvikle og forbedre sidene våre.
        </p>
        <p>
            Enkelte cookies er såkalte "session cookies". Disse slettes etter endt sesjon, det vil si når du lukker nettleseren din. Andre cookies er "faste" eller såkalte "persistent cookies". Disse slettes ikke etter endt sesjon, men har en utløpsdato.
        </p>
        <p>
            Vi gjør oppmerksom på at cookies er nødvendig å benytte dersom du skal logge inn på våre nettsider. Ved å surfe på våre nettsider aksepterer du at vi kan sette cookies i din nettleser.
        </p>
        <br>
        <hr>
        <br>
        <p>
            På våre websider bruker vi følgende cookies:
        </p>
        <p>
            <strong>JSESSIONID</strong><br>
            Dette er en standard Java-cookie som gjør det mulig for serveren å identifisere deg som kunde av våre tjenester etter innlogging med brukernavn og passord. Dette er en session cookie som slettes når du lukker nettleseren.
        </p>
        <p>
            <strong>_ga, _gid, _gat, _dc_gtm</strong><br>
            For å analysere trafikken på våre nettsider benytter vi oss av disse Google analytics cookies. De samler inn data for å gi statistikk som hjelper oss å forbedre nettsidene våre. Vi samler inn denne informasjonen på en måte som ikke identifiserer noen. Vi prøver ikke å finne identiteten til dem som besøker nettsiden vår, og vi forbinder ingen data som er samlet fra dette nettstedet med noen personlig identifiserbar informasjon fra andre kilder.
        </p>
        
        <h2>Deaktivere og slette cookies</h2>
        I alle nettlesere kan du bestemme om du ønsker å akseptere cookies automatisk eller ikke. Du har også muligheter til å slette cookies når du måtte ønske. For detaljer om dette se hjelp i egen nettleser eller følg den eksterne linken nedenfor for hjelp fra Post- og teletilsynet sine nettsider nettvett.no.
        
        <p>
            Slik administrerer du cookies i nettleseren:<br>
            <a href="https://nettvett.no/slik-administrer-du-informasjonskapsler/">https://nettvett.no/slik-administrer-du-informasjonskapsler/</a>
        </p>
        <end-page/>
    </div>
</template>

<script>
export default {
    
}
</script>