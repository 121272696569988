<template>
    <div class="flex justify-between">

        <div class="sm:w-2/5 sm:px-10 lg:px-20 sm:pt-10 xl:pt-20 pb-20 text-white hidden sm:block -mx-4 sm:-mx-6 lg:-mx-12 xl:-mx-24" style="background-image: url('/img/background-tall.svg')">
            <div class="lg:text-2xl xl:text-4xl font-semibold leading-snug" v-html="leftHeading"></div>
            <div class="pt-8" v-html="leftSubText"></div>

            <div class="sm:text-xl lg:text-2xl font-semibold lg:pt-20 xl:pt-40 pb-4" v-html="leftHeading2"></div>
            <div class="flex pb-2" v-for="(text, i) in leftTicks" :key="i">
                <img class="pr-2" src="/img/icon/tick.svg"> <span>{{ text }}</span>
            </div>            
        </div>

        <div class="w-full sm:w-3/5 pt-10 pb-4 px-2 sm:px-20">

            <div class="wizard flex text-center text-sm">
                <div class="w-1/3 relative" v-for="(wizard, i) in wizardSteps" :key="i">
                    <div :class="{ active : activePage >= i+1 }">
                        <div class="wizard-line"></div>
                        <img :src="wizard.icon" alt="">
                        {{ wizard.label }}
                    </div>
                </div>
            </div>

            <div class="pt-6 pb-2 font-semibold text-gray-900 leading-tight" v-html="heading"></div>
            <div v-if="subHeading" class="pb-4" v-html="subHeading"></div> 

            <form @submit.prevent="submitOrder"> 
                <router-view :key="$route.fullPath" @value-updated="inputValueUpdated"></router-view>
                <div class="flex justify-between pb-2">
                    <button @click.prevent="prevPage" class="btn btn-gray px-8 sm:px-16" v-if="!isFirstPage && !isLastPage">{{ btnPrev }}</button>
                    <button @click="nextPage" class="btn btn-blue px-8 sm:px-16" v-if="!isLastPage" :class="{ 'w-full': isFirstPage }">{{ btnNextText }}</button>
                </div>             
            </form>

            <div class="text-red-600 font-semibold text-sm text-center">{{ errorMsg }}</div>

        </div>
    </div>
</template>

<script>
import { Settings } from '@/main.js';
import axios from 'axios';


export default {
    name: 'Order',
    data() {
        return {
            pageCount: 3,
            activePage: 1,
            isFirstPage: false,
            isLastPage: false,
            isOrderPage: false,
            heading: '',
            subHeading: '',
            btnNextText: '',
            orderForm: {
                name: '', contact: '', email: '', phone: '', products: []
            },
            errorMsg: '',
            
            // -------- Texts / Content -----------------
            wizardSteps: [
                {
                    label: '1. Velg tjenester',
                    icon: '/img/icon/wizard-product.svg'
                },
                {
                    label: '2. Kontaktinfo',
                    icon: '/img/icon/wizard-info.svg'                    
                },
                {
                    label: '3. Klar til bruk',
                    icon: '/img/icon/wizard-finish.svg'                    
                }                
            ],
            pageHeadings: [
                'Prøvekonto SMS-tjeneste',
                'Prøvekonto SMS-tjeneste',
                'Takk for din bestilling!'
            ],
            subHeadings: [
                '',
                'Registrer kontaktinformasjon',
                ''
            ],
            btnNext: 'Neste',
            btnPrev: 'Forrige',
            btnOrder: 'Bestill',
            leftHeading: 'Opprett en gratis prøvekonto!',
            leftSubText: 'Prøv gratis og uforpliktende. Dersom du liker tjenesten kan du enkelt oppgradere til en ordinær konto senere. Da viderefører du alle innstillinger og mottakere du har lagt inn.',
            leftHeading2: 'Hvorfor?',
            leftTicks: [
                'Lag en prøvekonto på 1-2-3',
                'Brukervennlig, fleksibel og enkel',
                'Utviklet spesielt for norske kunder'
            ],
            orderError: 'Det oppstod en feil. Vennligst forsøk igjen eller kontakt oss på post@sveve.no',
        }
    },
    methods: {        
        nextPage: function() {            
            if (!this.isOrderPage) {
                let page = this.getSubPage();            
                page++;
                this.$router.replace(page.toString());
            }
        },
        prevPage: function() {
            if (!this.isFirstPage) {
                let page = this.getSubPage();
                page--;
                this.$router.replace(page.toString());
            }
        },
        getSubPage: function() {
            let r = this.$route.path.split('/');
            return r[r.length-1]*1;
        },
        inputValueUpdated: function(name, value) {
            this.orderForm[name] = value;
        },
        submitOrder: function() {                
            this.orderForm.products = this.$store.getters.products;      
            const options = {
                url: Settings.server.api + '/signup',
                method: 'POST',
                data: this.orderForm,
                timeout: 3000
            };
            axios(options).then(response => {                   
                if (response.data.success) {   
                    response.data.transaction.event = 'purchase';   // custom event name for GTM trigger
                    const trialOrder = {
                        autoApproved: response.data.autoApproved,
                        transactionObject: response.data.transaction
                    }                                    
                    this.$store.commit('setTrialOrder', trialOrder);
                    this.$router.replace(this.pageCount.toString());
                }
                else {                    
                    this.errorMsg = this.orderError;
                }
            }).catch(error => {
                console.log(error);
                this.errorMsg = error.message;
            });      
        }        
    },
    mounted: function() {
        let p = this.getSubPage();
        this.isFirstPage = p === 1;
        this.isLastPage = p === this.pageCount;
        this.heading = this.pageHeadings[p-1];
        this.subHeading = this.subHeadings[p-1];
        this.isOrderPage = p === this.pageCount-1;
        this.btnNextText = this.isOrderPage ? this.btnOrder : this.btnNext;

        this.activePage = p;  
    }
}
</script>