<template>
    <div class="md:mx-10 xl:mx-20">

        <div class="sm:flex justify-between">
            <div class="sm:w-3/5 sm:pr-20">
                <h1 v-html="heading"></h1>
                <div class="py-4" v-html="subHeading"></div>
                <div class="-ml-4 lg:-ml-10 xl:-ml-20"><img src="/img/plane-blue-right.svg"></div>
            </div>
            <div class="hidden sm:block sm:w-2/5 ">
                <img src="/img/illustration-contact.svg">        
            </div>
        </div>

        <div v-if="chatActive" class="pb-12">
            <div class="text-2xl font-semibold text-gray-900">{{ chatHeading }}</div>
            <div class="pt-2 pb-3"><span class="text-green-600 text-xl animate-pulse">&#11044;</span> {{ chatText }}</div>
            <button @click="openChat()" class="btn btn-blue">{{ chatBtn }}</button>
        </div>

        <div class="sm:flex justify-between">
            <div class="sm:w-2/5">            
                <div class="text-2xl pb-2 sm:pb-4 font-semibold text-gray-900" v-html="formHeading"></div>
                <form class="w-full flex flex-col" @submit.prevent="submitContact">
                    <InputField type="text" v-model="contactForm.name" image="/img/icon/name.svg" :placeholder="contactName" />
                    <InputField type="email" v-model="contactForm.email" image="/img/icon/email.svg" :placeholder="contactEmail" required />
                    <TextArea v-model="contactForm.msg" _class="h-48" image="/img/icon/message.svg" :placeholder="contactMsg" required />
                    <!--VueRecaptcha /-->
                    <button class="btn btn-blue mt-4">{{ sendBtn }}</button>                    
                    <div class="text-center pt-2 pb-10 text-green-800 text-sm font-semibold animate-pulse h-4" v-html="contactSubmittedMsg"></div>
                </form>         
            </div>
            <div class="hidden lg:block"> 
                <img class="pt-20 mx-auto" src="/img/plane-green-left.svg">
            </div>
            <div class="sm:w-2/5 sm:pt-10 text-sm">
                <div>
                    <h2 v-html="subHeading1"></h2>                
                    <div class="flex" v-for="(text, i) in sub1Text" :key="i">
                        <img class="pr-2" src="/img/icon/tick-small.svg" alt=""> <span>{{text}}</span>
                    </div>
                </div>
                <div>
                    <h2 v-html="subHeading2"></h2>
                    <div v-html="sub2Text"></div>
                </div>            
                <div>
                    <h2 v-html="subHeading3"></h2>
                    <div v-html="sub3Text"></div>
                </div>            
            </div>
        </div>

    </div>
    <end-page />
</template>

<script>
import { Settings } from '@/main.js';
import axios from 'axios';
import InputField from '@/components/InputField.vue';
import TextArea from '@/components/TextArea.vue';
//import VueRecaptcha from 'vue-recaptcha-v3';

export default {
    name: 'Contact',
    components: {
        InputField,
        TextArea,
        //VueRecaptcha,
    },
    data() {
        return {
            contactForm: {
                name: '', email: '', msg: '',
            },   
            contactSubmittedMsg: '',
            chatActive: false,

            // ----------- Texts --------------------
            heading: 'Hva kan vi hjelpe deg med?',
            subHeading: 'Send oss gjerne en henvendelse dersom det er noe du lurer på. Vi svarer deg så fort vi kan. Vi leser henvendelser store deler av døgnet, og du får alltid svar!',
            chatHeading: 'Chat med oss',
            chatText: 'Chatten er åpen og vi hjelper deg gjerne!',
            chatBtn: 'Start chat',
            formHeading: 'Kontakt oss',
            contactName: 'Firmanavn',
            contactEmail: 'E-postadresse',
            contactMsg: 'Hva ønsker du hjelp til?',
            sendBtn: 'Send',
            contactSubmittedOk: 'Din melding er mottatt', 
            contactSubmittedErr: 'Det oppstod en feil, vennligst forsøk igjen eller send en e-post til post@sveve.no',
            subHeading1: 'E-post',
            sub1Text: [
                'post@sveve.no',            
            ],
            subHeading2: 'Slik kommer du i kontakt med oss',
            sub2Text: 'Du kommer raskest i kontakt med oss ved å bruke chat, kontaktskjemaet eller e-postadresse: post@sveve.no. NB! Vi har for tiden ikke noe bemannet sentralbord/telefon, så ta gjerne kontakt på chat eller e-post for å avtale telefonsupport',
            subHeading3: '',
            sub3Text: ''
        }
    },
    created: function() {
        if (window.tidioChatApi) {
            window.tidioChatApi.on("ready", this.onTidioChatApiReady);
        } else {
            document.addEventListener("tidioChat-ready", this.onTidioChatApiReady);
        } 
    },
    methods: {
        onTidioChatApiReady: function() {            
            this.chatActive = true;
        },        
        openChat: function() {
            if (window.tidioChatApi) {
                window.tidioChatApi.open();
            }
        },
        submitContact: function() {
            const options = {
                url: Settings.server.api + '/contact',
                method: 'POST',
                data: this.contactForm,
                timeout: 3000          
            };
            axios(options).then(response => {  
                this.contactSubmittedMsg = response.data.success ? this.contactSubmittedOk : this.contactSubmittedErr;
                setTimeout(() => this.contactSubmittedMsg = '', 4000);
                if (response.data.success) {
                    this.contactForm.name = '';
                    this.contactForm.email = '';
                    this.contactForm.msg = '';
                }
            }).catch(error => {
                console.log(error);
                this.contactSubmittedMsg = this.contactSubmittedErr;
            });
        }
    }
}
</script>