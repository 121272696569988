<template>

    <div class="pb-4">
        <InputField type="text" @change="inputUpdate($event, 'name')" image="/img/icon/house.svg" :placeholder="namePlaceholder" required />
        <InputField type="text" @change="inputUpdate($event, 'contact')" image="/img/icon/name.svg" :placeholder="contactPlaceholder" required />
        <InputField type="email" @change="inputUpdate($event, 'email')" image="/img/icon/email.svg" :placeholder="emailPlaceholder" required />
        <InputField type="tel" @change="inputUpdate($event, 'phone')" image="/img/icon/phone.svg" :placeholder="phonePlaceholder" />
        <div class="sm:hidden">{{ mobileInfoText }}</div>
    </div>

</template>

<script>
import InputField from '@/components/InputField.vue';

export default {
    components: {
        InputField
    },
    data() {
        return {
            // -------- Texts / Content -----------------
            subHeading: 'Fyll inn info...',        
            namePlaceholder: 'Firmanavn',
            contactPlaceholder: 'Kontaktperson',
            emailPlaceholder: 'E-postadresse',
            phonePlaceholder: 'Mobilnummer',
            mobileInfoText: 'Prøvekonto er gratis og uforpliktende. Dersom du liker tjenesten kan du enkelt oppgradere til en ordinær konto senere.',
        }
    },
    methods: {
        inputUpdate: function(e, name) {        
            this.$emit('value-updated', name, e.target.value)
        }
    }
}
</script>