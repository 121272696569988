<template>
    <div class="overflow-x-auto">
    <table class="table-auto api-table">
        <thead>
            <tr><th v-for="(col, h) in tableData.columns" :key="h">{{ col }}</th></tr>
        </thead>
        <tbody>
            <tr v-for="(row, r) in tableData.rows" :key="r">
                <td :class="{'bg-gray-200 font-semibold': tableData.highlightColumn == c}" v-for="(col, c) in row" :key="c" v-html="col"></td>                                
            </tr>
        </tbody>
    </table>    
    </div>
</template>

<script>
export default {
    props: ['tableData']
}
</script>