<template>
    <div class="sm:mx-20 lg:mx-40">
        <h1>Om Sveve</h1>
        <div class="py-4">      
            <p>Sveve AS tilbyr SMS-tjenester og mobil kommunikasjon til norske bedrifter og organisasjoner. Siden 2006 har vi jobbet for å gjøre det enkelt å kommunisere enkelt og effektivt med kunder, ansatte eller medlemmer.</p>
            <p>Det er nå flere tusen norske kunder som bruker Sveve som verktøy i sin daglige kommunikasjon. Kundebasen vår spenner fra små bedrifter og organisasjoner til store børsnoterte selskaper og offentlige etater. Vi er stolte over svært mange fornøyde kunder og langsiktige kundeforhold - noen kunder har vært med oss helt fra starten for over 10 år siden!</p>
            <p>Sveve er utviklet i Norge og spesialtilpasset til norske bedrifter og organisasjoner. Tjenesten er under kontinuerlig utvikling og forbedring. I tillegg til standard-tjenester som er klare til bruk umiddelbart, tilbyr vi også spesialutvikling og skreddersydde løsninger for kommunikasjon, markedsføring og varsling.</p>
            <p>Sveve ble startet av de to sivilingeniørene fra NTH/NTNU, Ole Magnus Habberstad og Ivar Løset Moen i 2006, og har siden da vokst til å bli en av norges største tilbydere av SMS-tjenester og mobil kommunikasjon.</p>
            <p>Vil du komme i kontakt med oss så hører vi gjerne fra deg på e-post: post@sveve.no, via chat eller kontaktskjema.</p>
        </div>
    </div>
    <end-page />
</template>

<script>
export default {
    name: 'About'
}
</script>