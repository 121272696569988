<template>
    <div :class="{ 'border-gray-600' : isSelected, 'bg-gray-200': alwaysOn }" class="trial-option-card">

        <div class="flex">            
            <img @click="toggleProduct" :src="isSelected ? '/img/icon/checkbox-checked.svg' : '/img/icon/checkbox-clear.svg'" :class="{ pointer: !alwaysOn }" alt="">
            <div class="w-full flex justify-between">
                <div @click="toggleProduct" class="pl-4 w-full" :class="{ pointer: !alwaysOn }">
                    <div class="font-semibold text-lg text-gray-800">{{ name }}</div>
                    <div class="text-sm">{{ sub }}</div>
                </div>
                <div @click="toggleOpen" class="text-right p-4 pt-5 pointer">
                    <img :src="isOpen ? '/img/icon/arrow-up.svg' : '/img/icon/arrow-down.svg'" alt="">
                </div>
            </div>
        </div>
                
        <div v-if="isOpen" class="pl-2 pt-3">
            <div class="text-sm" v-html="desc"></div>
            <div class="text-sm _font-semibold text-right pt-2 pr-4 sm:pr-8">                
                <div><strong>Gratis å prøve</strong> - 
                <span v-if="alwaysOn">ordinær pris</span><span v-else>tillegg</span> kr {{ price }},-<small>/mnd</small></div>
                <div v-if="startup"><small>Etablering: {{ startup }},-</small></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OptionCard',
    props: { 
        id: String, 
        name: String, 
        sub: String, 
        desc: String, 
        price: Number, 
        startup: Number, 
        alwaysOn: Boolean, 
    },
    data() {
        return {
            isOpen: false,
            isSelected: false,
        }
    },
    methods: {
        toggleProduct: function() {
            if (this.alwaysOn !== true) {
                this.isSelected = !this.isSelected;
                this.isSelected ? this.$store.commit('addProduct', this.id, false) : this.$store.commit('removeProduct', this.id)
            }
        },
        toggleOpen: function() {
            this.isOpen = !this.isOpen;
        }
    },
    created: function() {
        if (this.alwaysOn === true) {
            this.isSelected = true;
        }
        else {
            this.$store.dispatch('hasProduct', this.id).then((selected) => {
                this.isSelected = selected;            
            });
        }
    }
}
</script>