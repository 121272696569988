<template>
    <div class="border border-gray-300 bg-white rounded-2xl mb-3 px-4 sm:px-6 py-2 sm:py-3">
        <div @click="isOpen = !isOpen" class="flex justify-between pointer">
            <div class="mt-1 pr-2" :class="{'font-semibold': isOpen}">{{ heading }}</div>
            <img :src="isOpen ? '/img/icon/collapse-minus.svg' : '/img/icon/expand-plus.svg'" alt="">
        </div>
        <div class="my-2 text-sm" v-if="isOpen" v-html="text"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false
        }
    },
    props: [ 'heading', 'text' ]
}
</script>