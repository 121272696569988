<template>
    <div :class="{'h-screen': !pageRenderFinish}">
        <div v-if="pageRenderFinish">
            <div class="relative">
                <div class="absolute pl-8 lg:pl-20 pt-6 hidden sm:block">
                    <img src="/img/plane-blue-right.svg" alt="">
                </div>
                <div class="text-center">
                    <h1 class="mb-2 sm:mb-4 text-2xl sm:text-3xl" v-html="heading"></h1>
                    <div class="text-sm sm:text-lg" v-html="subHeading"></div>
                </div>    
            </div>

            <div class="w-full mx-auto border-t border-dashed border-gray-300 my-4 sm:my-8"></div>

            <div class="sm:flex">
                <!-- topic menu -->
                <div class="sm:pr-20 lg:pr-40 relative">
                    <div class="absolute h-full border-l-2 border-dashed"></div>
                    <div v-for="(faqGroup, t) in faq" :key="t">
                        <div @click="activeTopic = t" :class="{'font-bold text-gray-800': activeTopic === t}" class="pl-4 pointer my-4">
                            <div class="flex relative">
                                <div v-if="activeTopic === t" class="h-full absolute -ml-4 border-l-4 border-blue-600"></div>
                                <div>{{ faqGroup.topic }}</div>
                            </div>
                        </div>
                    </div>
                    <img class="hidden sm:block pt-16 pl-6" src="/img/plane-green-left.svg" alt="">    
                </div>        

                <!-- faq items -->
                <div class="w-full">
                    <div v-for="(faqGroup, f) in faq" :key="f">                
                        <div v-for="(helpItem, i) in faqGroup.content" :key="i">                    
                            <HelpCard v-if="activeTopic === f" :heading="helpItem.heading" :text="helpItem.text" />            
                        </div>                 
                    </div>
                </div>
            </div>    
        </div>
    </div>

    <end-page v-if="pageRenderFinish" />
</template>

<script>
import { Settings } from '@/main.js';
import * as contentful from 'contentful';
import HelpCard from '@/components/HelpCard.vue';


export default {
    components: {
        HelpCard
    },
    data() {
        return {
            activeTopic: 0,
            pageRenderFinish: false,

            heading: '',
            subHeading: '',
            faq: [],
        }
    },
    methods: {
        getContent: function() {
            const cmsClient = contentful.createClient({
                space: Settings.contentful.space, 
                accessToken: Settings.contentful.accessToken
            });                
            cmsClient.getEntries({ content_type: 'help' }).then((entries) => {                        
                if (entries.items && entries.items.length) {
                    let contentJson = entries.items[0].fields.content;
                    for (let prop in contentJson) {
                        this[prop] = contentJson[prop];
                    }
                }
                this.pageRenderFinish = true;
            });            
        }
    },    
    created: function() {
        this.getContent();
    }    
}
</script>