<template>
     <img v-bind:src="src" :width="width" :height="height" v-bind:alt="alt" :class="_class">
</template>

<script>
export default {
    props: {
        _class: String,
        attr: Object
    },
    computed: {
        src: function() {
            return this.attr.src;
        },
        width: function() {
            return this.attr.width;
        },
        height: function() {
            return this.attr.height;
        },
        alt: function() {
            return this.attr.alt;
        }                        
    }
}
</script>