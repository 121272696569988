<template>
       
    <div class="flex justify-between">

        <div class="h-screen sm:w-2/5 sm:px-10 lg:px-20 sm:pt-10 pb-20 xl:pt-20 text-white hidden sm:block -mx-4 sm:-mx-6 lg:-mx-12 xl:-mx-24" style="background-image: url('/img/background-tall.svg')">
            <div class="text-4xl font-semibold leading-snug" v-html="leftHeading"></div>
            <div class="pt-8" v-html="leftSubText"></div>

            <div class="sm:text-xl lg:text-2xl font-semibold pt-20 pb-4" v-html="leftHeading2"></div>
            <div class="flex pb-2" v-for="(text, i) in leftTicks" :key="i">
                <img class="pr-2" src="/img/icon/tick.svg"> <span>{{ text }}</span>
            </div>            
        </div>
        
        <div class="w-3/4 sm:w-2/5 lg:w-1/3 mx-auto pt-10 sm:py-20">

            <!--div class="text-lg_ alert-box alert-error text-center mx-auto py-6">                       
                Innlogging vil være utilgjengelig i perioder torsdag 4. januar mellom kl 21 og midnatt grunnet vedlikehold
            </div-->            

            <div class="alert-box alert-error" v-if="errorMsg" v-html="errorMsg"></div>
            <div class="alert-box alert-info" v-if="infoMsg" v-html="infoMsg"></div>
            <div class="alert-box alert-success" v-if="successMsg" v-html="successMsg"></div>

            <h1 v-if="forgotPasswordMode">Glemt passord</h1>
            <h1 v-else>Logg inn</h1>

            <div v-if="forgotPasswordMode" class="pt-4">
                Skriv inn brukernavnet og e-post adressen du er registrert med hos oss. Du vil motta en e-post med instruksjoner om hvordan du resetter ditt passord.
            </div>
            <div v-else-if="otpMode" class="pt-4">
                Du mottar engangskode {{ otpMessage }}
            </div>

            <form class="flex flex-col justify-center mt-4" method="post" :action="forgotPasswordMode ? passwordUrl : loginUrl" @submit.prevent="doLogin()" ref="loginForm">        
                <div v-if="otpMode">
                    <InputField type="text" name="otp" v-model="otp.otpCode" image="/img/icon/password.svg" placeholder="Engangskode" required />
                </div>
                <div v-else>
                    <InputField type="text" name="user" v-model="username" image="/img/icon/name.svg" placeholder="Brukernavn" required />
                    <InputField v-if="forgotPasswordMode" type="email" name="email" v-model="email" image="/img/icon/email.svg" placeholder="E-postadresse" required />
                    <InputField v-else type="password" name="passwd" v-model="password" image="/img/icon/password.svg" placeholder="Passord" />
                    <div class="text-right text-base">                    
                        <a href="#" @click.prevent="toggleForgotPasswordMode()">{{ forgotPasswordMode ? 'Logg inn' : 'Glemt passord?' }}</a>
                    </div>                
                </div>
                <button class="btn btn-blue mt-6">{{ forgotPasswordMode ? 'Resett passord' : 'Logg inn' }}</button>
                <input type="hidden" name="origin" :value="appRoot">           
            </form>

            <div class="text-right mt-3">
                <div @click="toggleGoogleBtn" class="cursor-pointer text-sm mb-1 underline">Alternativ innlogging</div>
                <div class="float-right" :class="showGoogleBtn ? 'visible' : 'hidden'">
                    <div id="g_id_onload"
                        data-client_id="214751689930-b2vqr79mig4h09ukgrek0th9sjlhhle3.apps.googleusercontent.com"
                        data-login_uri-dev="http://localhost:8080/SMS/Login"
                        data-login_uri="https://sveve.no/SMS/Login"
                        data-auto_prompt="false"
                    >
                    </div>
                    <div class="g_id_signin"
                        data-type="standard"
                        data-size="large"
                        data-theme="outline"
                        data-text="sign_in_with"
                        data-shape="rectangular" 
                        data-logo_alignment="left">
                    </div>  
                    <div class="float-right text-xs">Bare for kunder med spesialavtale</div>                       
                </div>
            </div>                 
        </div>
    </div>
    
</template>

<script>
import InputField from '@/components/InputField.vue';
import { Settings } from '@/main.js';
import axios from 'axios';


export default {
    components: {
        InputField
    },
    computed: {
        otpMessage() {
            const otpNum = this.otp.otpNumber;
            if (otpNum) {
                return 'til ' + otpNum.substring(0,2) + '****' + otpNum.substring(6,otpNum.length);            
            }
            return 'på SMS';
        }
    },
    data() {
        return {            
            v1Users: [],           
            forgotPasswordMode: false,
            otpMode: false,
            username: '',
            password: '',
            email: '',
            otp: {
                otpCode: '',
            },
            showGoogleBtn: true,  // must be visible on init
            loginUrl: '',            
            passwordUrl: '',
            appRoot: '',
            errorMsg: '',
            infoMsg: '',
            successMsg: '',

            // -------- Texts -----------------
            leftHeading: 'Velkommen!',
            leftSubText: 'Velkommen tilbake til Sveve! Logg inn for å sende SMS og administrere din konto',
            leftHeading2: 'Hvorfor Sveve?',
            leftTicks: [
                'Brukervennlig og enkel',
                'Effektiv kommunikasjon',
                'Norsk kvalitetsleverandør'
            ],                        
        }
    },    
    created() {
        this.getv1Users();
        this.showCustomerMessage();
    },
    mounted() {       
        this.loginUrl = Settings.server.login;
        this.passwordUrl = Settings.server.password;
        this.appRoot = Settings.app.root + '/login';
        this.checkParams();
        
        let externalScript = document.createElement('script');
        externalScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
        document.head.appendChild(externalScript);
        externalScript.onload = () => {
            this.showGoogleBtn = false;
        };        
    },
    methods: {
        checkParams: function() {
            let q = (this.$route.query);  // get url query parameter
            if ('e' in q || 's' in q || 'o' in q || 'g' in q) {  // (e)rror, (s)uccess, (o)tp, (g)oogle-auth-error  (old version)
                this.showLoginMessage(q);                        
            }
            else if ('code' in q) {  // callback from Microsoft auth (v2)
                const auth = {
                    code: q.code,
                    username: q.username,
                };
                this.doLogin(auth);
            }
            else if ('gauth' in q) {            
                const auth = {
                    googleCredential: q.gauth,
                };
                this.doLogin(auth);
            }
            else if ('admin' in q && 'dest' in q) {
                const auth = {
                    username: q.user,
                    password: q.passwd,
                    dest: q.dest,
                }
                this.doLogin(auth);
            }
            else {
                let refreshToken = this.$cookie.getCookie('refresh_token');
                if (refreshToken) {                
                    window.location.href = Settings.server.sms_app;   // try auto-login
                } 
            }
        },
        getv1Users: function() {
            const url = window.location.hostname === 'localhost' ? '/v1users.json' : 'https://sveve.no/w1/v1users.json';
            axios.get(url).then(res => {
                this.v1Users = res.data;
            });
        },
        doLogin: function(authDataOverride) { 
            const v1 = this.v1Users.includes(this.username);
            const v1Login = this.forgotPasswordMode || v1; // || (!v2 && !authDataOverride);
            if (v1Login) {   // login old version or reset password
                this.$cookie.setCookie('username', this.username); 
                this.$refs.loginForm.submit();  // normal form submit to old backend                
            }
            else {
                let authData = this.otpMode ? this.otp :
                {
                    username: this.username,
                    password: this.password,
                };
                if (authDataOverride) {
                    authData = authDataOverride;
                }
                this.errorMsg = '';
                axios.post(Settings.server.api + '/auth', authData)
                    .then(res => {   
                        if (res.data.msLoginUrl) {  // Microsoft login redirect url
                            window.location.href = res.data.msLoginUrl;
                        }                  
                        else if (res.data.otp) {  // OTP required
                            this.otp = res.data;
                            this.otpMode = true;
                        }
                        else {
                            this.$cookie.setCookie('refresh_token', res.data.refresh_token, {
                                expire: res.data.expire,
                                sameSite: 'Lax',
                                //secure: true
                                //https://github.com/anish2690/vue-cookie-next
                            });
                            const dest = authData.dest || Settings.server.sms_app; 
                            window.location.href = dest;   // redirect to sms webapp  
                        }
                    })
                    .catch (error => {
                        console.log(error.response);
                        if (error.response.data) {
                            this.errorMsg  = error.response.data;
                            this.otpMode = false;
                        }
                    }
                );
            }
        },
        toggleForgotPasswordMode: function() {            
            this.forgotPasswordMode = !this.forgotPasswordMode;
            this.errorMsg = '';
            this.successMsg = '';
        },
        toggleGoogleBtn: function() {
            this.showGoogleBtn = !this.showGoogleBtn;
        },
        showLoginMessage: function(q) {
            if ('s' in q) {  // success
                switch (q.s) {
                    case 'p1': this.successMsg = 'En e-post har blitt sendt med instruksjoner om hvordan du kan resette ditt passord'; break;  
                    case 'p2': this.successMsg = 'En e-post har blitt sendt med ditt nye passord'; break;  
                }
            }
            else if ('e' in q || 'g' in q) {  // error 
                switch (q.e) {
                    case 'l': this.errorMsg = 'Feil brukernavn/passord'; break;  // login err
                    case 's': this.errorMsg = 'Kontoen er slettet'; break; // slettet
                    case 'i': this.errorMsg = 'Kontoen er deaktivert'; break;  // inaktiv
                    case 'g': this.errorMsg = 'Din Google-konto er ikke godkjent for innlogging hos Sveve'; break;
                    case 'p': // reset password
                        this.errorMsg = 'Kombinasjonen av e-post og brukernavn er ikke registrert hos oss. Vennligst forsøk på nytt.'; 
                        this.forgotPasswordMode = true;
                        break;  
                }
            }
            else if ('o' in q) {  // otp
                switch (q.o) {
                    case '1' : this.otpMode = true;
                }
            }
        },
        showCustomerMessage: function() {
            let username = this.$cookie.getCookie('username');
            if (username) {
                this.username = username;
                axios.get(Settings.server.api + '/custmsg', { params: { username: username } }).then(res => {                
                    this.infoMsg = res.data;
                });
            }
        }
    }
    
}
</script>